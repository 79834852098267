import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import {
    Tabs,
    TabPane,
} from 'element-ui'
import Satisfy from 'zy-satisfy'
import '@/styles/index.scss'
import 'swiper/dist/css/swiper.css'
import { goTop } from './utils/utils'
Vue.config.productionTip = false

Vue.use(Satisfy)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(VueAwesomeSwiper)
new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
    //页面跳转时候先回到顶部
router.beforeEach((to, from, next) => {
    if (to.path !== from.path) {
        goTop()
    }
    next()
})

Sentry.init({
    Vue,
    dsn: "https://a582fc124b6c4019adef236a0c38e83b@sentry-app.ireader.com/2",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "zhangyue.com", '59.151.100.24'],
        }),
    ],
    tracesSampleRate: 1.0,
    release: process.env.releaseVersion,
});
