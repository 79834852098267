<template>
    <div id="allnews" class="allnews">
        <div class="allnews_banner">
            <img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/banner_news.55b1139e.png" alt="" class="allnews_pc_banner">
            <img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/allnews_phone_banner.ab59e7a5.png" alt="" class="allnews_phone_banner">
        </div>
        <Breadbox />
        <div class="news_inner">
            <el-tabs tab-position="left" id="news_inner" @tab-click="clickTab">
                <el-tab-pane v-for="item in allnewsList" :key="item.title" :label="item.date">
                    <!-- <div>
                    {{item.date}}--{{item.list}}
                    </div> -->
                    <div class="newlist" v-for="item in item.list" :key="item.list">
                        <a :href="item.href" class="title" target="_blank">{{item.title}}</a>
                        <span class="newdate">{{item.date}}</span>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="phone_news">
            <el-tabs activate-name="second">
                <el-tab-pane v-for="item in allnewsList" :key="item.title" :label="item.date">
                    <div class="newlist" v-for="item in item.list" :key="item.list">
                        <a :href="item.href" class="title" target="_blank">{{item.title}}</a>
                        <span class="newdate">{{item.date}}</span>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Breadbox from "@/components/Breadbox";
import { allnewsList } from "@/config/allnews";
import { Mixins } from '@ireader/core-lib';
import { goTop } from '@/utils/utils';
export default {
    name:'allnews',
    mixins: [Mixins.sdd()],
    data:function(){
        return{
            allnewsList: []
        }
    },
    components: {
        Breadbox,
    },
    mounted() {
        this.getNewsList()
        window.addEventListener("scroll", this.onScroll , true);
    },
    destroyed: function() {
        window.removeEventListener("scroll", this.onScroll, true);
    },
    methods: {
        async getNewsList () {
            try {
                const res = await this.$getSdd('zycom/news_list')
                this.allnewsList = res || allnewsList;
            } catch (e) {
                this.allnewsList = allnewsList;
            }
        },
        onScroll() {
            const scrolled =
            document.documentElement.scrollTop || document.body.scrollTop;
            const current = document.getElementById('news_inner')
            const tabHeader = current.getElementsByClassName('el-tabs__header') && current.getElementsByClassName('el-tabs__header')[0]
            const tabContent = current.getElementsByClassName('el-tabs__content') && current.getElementsByClassName('el-tabs__content')[0]
            const windowHeight = document.documentElement.clientHeight || document.body.clientHeight; // 可视区的高度
            const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            const footerHeight = document.getElementById('contact') &&  document.getElementById('contact').clientHeight

            const setStyle = (position, top, marginLeft) => {
                tabHeader.style.position = position
                tabHeader.style.top = top
                tabContent.style.marginLeft = marginLeft
            }

            const allnews = document.getElementById('allnews')
            const allnewsBannerHeight  = allnews.getElementsByClassName('allnews_banner') && allnews.getElementsByClassName('allnews_banner')[0] && allnews.getElementsByClassName('allnews_banner')[0].clientHeight
            // 页面滑动到底部时直接定位到最后的footer
            if ((scrolled + windowHeight >= scrollHeight - 88 - 40) || (scrollHeight - scrolled - windowHeight) <= (footerHeight/2)) {
                setStyle('unset', 0, 0)
            } else if (scrolled > allnewsBannerHeight + 10) {
                setStyle('fixed', '219px', '308px')
            } else {
                setStyle('unset', 0, 0)
            }
        },
        // 左侧年份点击
        clickTab(){
            goTop()
        }

    }
    
}
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
@import "@/styles/mixin.scss";
body{background:#fff;}
.phone_news{
    display: none;
}
.allnews{margin-top:82px;}
.allnews_banner{
    text-align: center;
    img{
        width: 100%;
        display: block;
        &.allnews_phone_banner{
            display: none;
        }
    }
}
.news_inner{
    width: 992px;
    padding:88px 0;
    margin:0 auto;
    .el-tabs__nav-wrap:after{
        display: none;
    }
    .el-tabs--left .el-tabs__active-bar.is-left, .el-tabs--left .el-tabs__nav-wrap.is-left:after{display: none;}
    .el-tabs--left .el-tabs__item.is-left{text-align: left;}
    .el-tabs__item{
        height: 22px;
        line-height: 22px;
        padding-bottom: 68px;
        font-size:16px;
        color:#666;
        font-weight: normal;
    }
    .el-tabs__item.is-active{
        color:#FC5649;
        font-size: 28px;
    }
    .el-tabs--left .el-tabs__header.is-left{
        width: 160px;
        margin-right: 148px;
    }
    .newlist{
        padding-bottom: 40px;
        a{
            display: block;
            height: 28px;
            line-height: 28px;
            font-size: 16px;
            color:#333;
        }
        .newdate{
            line-height: 40px;
            padding-top:20px;
            font-size:14px;
            color:#999;
        }
    }
}
.S{
    .breadEval,.news_inner{display: none;}
    .allnews_banner{
        img{
            &.allnews_phone_banner{
                display: block;
            }
            &.allnews_pc_banner{
                display: none;
            }
        }
    }
    .allnews{
        margin-top: rem(158);
    }
    .phone_news{
        display: block;
        .el-tabs__item{
            padding:0 rem(44);
            height: rem(122);
            line-height: rem(122);
            color:#666;
            font-size:rem(42);
            &.is-active{
                color:#333
            }
        }
        .el-tabs__active-bar{
            left:rem(17);
            background-color: #FC5649;
        }
        .el-tabs__nav-wrap,.el-tabs__nav-scroll,.el-tabs__nav{
            padding:0 rem(16);
            height: rem(122);
            background: #FBFBFB;
        }
        .el-tabs__nav-wrap:after{
            height: rem(3);
            background: #f5f5f5;
        }
        .el-tabs__content{
            padding: rem(76) rem(72);
        }
        .newlist{
            padding:rem(48) 0;
            border-bottom: rem(3) solid #FAFAFA;
            .title{
                display: block;
                font-size:rem(48);
                color:#333;
            }
            .newdate{
                line-height: rem(52);
                padding-top: rem(32);
                color:#999;
                font-size: rem(42);
            }
        }
    }
}
</style>