import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'
import About from '../views/About/about.vue'
import Allnews from '../views/Allnews/allnews.vue'
import Alldevelop from '../views/Alldevelop/alldevelop.vue';
import Allhonor from '../views/Allhonor/allhonor.vue'
import Allreps from '../views/Allreps/allreps.vue'
import Terms from '../views/Terms/terms.vue'
import Copyright from '../views/Copyright/copyright.vue'
import Privacy from '../views/Privacy/privacy.vue'
import Zykw from '../views/Zykw/zykw.vue'
import Zybq from '../views/Zybq/zybq.vue'
import Advantage from '../views/Advantage/advantage.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/allnews',
        name: 'Allnews',
        component: Allnews,
        meta: {
            breadList: [{
                name: "首页",
                path: "/home"
            }, {
                name: "更多动态",
                path: "/home/allnews"
            }]
        }
    },
    {
        path: '/alldevelop',
        name: 'Alldevelop',
        component: Alldevelop,
        meta: {
            breadList: [{
                name: "首页",
                path: "/home"
            }, {
                name: "发展历程",
                path: "/about"
            }, {
                name: "全部历程",
                path: "/home/about/alldevelop"
            }]
        }
    },
    {
        path: '/allhonor',
        name: 'Allhonor',
        component: Allhonor,
        meta: {
            breadList: [{
                name: "首页",
                path: "/home"
            }, {
                name: "荣誉奖项",
                path: "/about"
            }, {
                name: "全部荣誉",
                path: "/home/about/allhonor"
            }]
        }
    },
    {
        path: '/allreps',
        name: 'Allreps',
        component: Allreps,
        meta: {
            breadList: [{
                name: "首页",
                path: "/home"
            }, {
                name: "文章详情",
                path: "/home/allreps"
            }]
        }
    }, {
        path: '/terms',
        name: 'Terms',
        component: Terms
    }, {
        path: '/copyright',
        name: 'Copyright',
        component: Copyright
    }, {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    }, {
        path: '/zykw',
        name: 'Zykw',
        component: Zykw
    }, {
        path: '/zybq',
        name: 'Zybq',
        component: Zybq
    }, {
        path: '/advantage',
        name: 'Advantage',
        component: Advantage
    },
    {
        path: '*',
        name: 'Home',
        component: Home
    }
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]


const router = new VueRouter({
    mode: "history",
    routes,
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router