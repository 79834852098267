<template>
    <div id="zykw" class="zykw">
        <div class="inner_kw">
            <div class="left_info">
                <div class="zykw_logo"></div>
                <div class="zykw_btm_down">
                    <div class="down_btn">
                        <a class="" v-for="item in downList"
            :class="item.icon"
            :key="item.name"
            :href="item.href">{{item.name}}</a>
                    </div>
                    <div class="zykw_qr"></div>
                </div>
            </div>
        </div>
        <div class="phone_kw">
            <div class="top_img"><img :src="`${efeUrl}/zykw_phone.fba9295a.png`" alt=""></div>
            <div class="down_btn">
                 <a class="" :href="isIos?downList[0].href:downList[0].href_app">立即下载</a>
            </div>
        </div>
    </div>
</template>
<script>
import { downList } from '@/config/about'
import { efeUrl } from '@/utils/utils';
export default {
    name:'Zykw',
    data:function(){
        return{
            efeUrl,
            downList,
            isIos: false
        }
    },
    created() {
        this.appSource();
    },
    methods:{
        appSource() {
            const u = navigator.userAgent;
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isiOS) {
                // return "ios";
                this.isIos = true
            } else {
                this.isIos = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zykw{
    padding-top:80px;
    min-height: 730px;
    background-image: linear-gradient(-135deg,#A3DAC1,#99DABC);
    .inner_kw{
        width: 992px;
        height: 730px;
        margin:0 auto;
        background:url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/zykw_bannerimg.28074894.png") no-repeat right center;
        background-size:645px 652px;
    }
    .left_info{
        padding-top:207px;
    }
    .zykw_logo{
        height: 149px;
        background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/zykw_logo.ef09dbda.png") no-repeat left center;
        background-size:363px 149px;
    }
    .zykw_qr {
        float: left;
        margin-left:16px;
        width: 110px;
        height: 110px;
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/zycw_qrcode_new.79adcbd9.png");
        background-size:110px;
    }
    .zykw_btm_down{
        margin-top:56px;
        overflow: hidden;
        .down_btn{
            width: 236px;
            float: left;
            a {
                display: block;
                width: 236px;
                height: 48px;
                padding-left: 10px;
                box-sizing: border-box;
                border-radius: 2px;
                border: 1px solid #666;
                margin-bottom: 14px;
                cursor: pointer;
                &:last-child {
                    margin-bottom: 0px;
                }
                &:before {
                    content: "";
                    width: 18px;
                    height: 22px;
                    display: inline-block;
                    margin: 11px 13px 12px 42px;
                    vertical-align: middle;
                    background-size: 100%;
                    background-repeat: no-repeat;
                }

                &.android {
                &:before {
                    margin: 12px 13px 14px 42px;
                    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/android.png");
                }
                span {
                    vertical-align: text-bottom;
                }
                }

                &.ios {
                &:before {
                    margin: 12px 13px 14px 42px;
                    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/ios.png");
                }

                span {
                    vertical-align: sub;
                }
                }

                &:hover {
                color: #fff;
                background-image: linear-gradient(to bottom right, #3BB379, #29B270);
                border: none;

                &.ios {
                    &:before {
                    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/ios_active.png");
                    }
                }
                &.android {
                    &:before {
                    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/android_active.png");
                    }
                }
                }
            }
        }
    }
}
.phone_kw{display: none;}
.S {
    .zykw{
        padding-top:0;
    }
    .inner_kw{
        display: none;
    }
    .phone_kw{
        padding: rem(296) 0 rem(168);
        display: block;
        .top_img{
            padding-bottom: rem(124);
        }
        img{
            width: 100%;
        }
        .down_btn{
            padding: 0 rem(60);
            a{
                display: block;
                height: rem(139);
                line-height: rem(139);
                text-align: center;
                -webkit-border-radius:rem(6);
                border-radius:rem(6);
                background: #29B270;
                font-size: rem(42);
                color:#fff;
            }
        }
   } 
}
</style>