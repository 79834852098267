<template>
  <div id="header" class="zy_header">
    <!-- <span class="zy_slider_bg"></span> -->
    <div id="contact_app" class="contact" :class="{ contact_opacity: opacity  }" @click="contact">
      <span class="icon" />职工书屋咨询
    </div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide>
        <span class="zy_slider_bg"></span>
      </swiper-slide>
      <swiper-slide>
        <span class="zy_slider_bg sec" @click.stop="goTo(isPhone() ? 'https://s.zhangyue.com/library_h5/wapProduct.html?t=ecard' : 'https://b.zhangyue.com/product.html#activeIndex=4')"></span>
      </swiper-slide>
      <swiper-slide>
        <span class="zy_slider_bg agent" @click.stop="goTo(isPhone() ? 'https://s.zhangyue.com/library_h5/wapAgent.html?s=zhangyue' : 'https://b.zhangyue.com/agent.html?s=zhangyue')"></span>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      opacity: false,
      t1: 0,
      t2: 0,
      timer: null,
      swiperOption: {
        loop: true,
        speed: 1500,
        slidesPerView: 1,
        autoplay: { delay: 4000, disableOnInteraction: false },
         pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
      },
    };
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed: function() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    isPhone() {
      const htmlClass = document.getElementsByTagName('html')[0] && document.getElementsByTagName('html')[0].classList;
      return htmlClass.toString().indexOf('S') > -1
    },
    onScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.t2 = document.documentElement.scrollTop || document.body.scrollTop;
        if (this.t2 == this.t1) {
          this.opacity = false;
        }
      }, 100);
      this.t1 = scrollTop;
      this.opacity = true;
    },
    goTo(url) {
      window.location.href = url;
    },
    contact () {
      const url = 'https://p.qiao.baidu.com/cps/chat?siteId=14993897&userId=30402531&siteToken=1a3bce93f476da2dd0fd0c09bca980ee'
      if (this.isPhone()) {
        window.location.href = url;
      } else {
        window.open(url);
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zy_header {
  height: 602px;
  position: relative;
  color: #fff;
  .swiper {
    height: 100%;

    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 16px;
        height: 16px;
        background-color: unset;
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
      }
      .swiper-pagination-bullet-active {
        border: none;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .contact {
    position: fixed;
    // width: 158px;
    padding: 0px 24px 0px 20px;
    height: 60px;
    line-height: 60px;
    right: 20px;
    bottom: 50px;
    border: 1px solid #E6E6E6;
    border-radius: 30px;
    z-index: 9;
    box-sizing: content-box;
    color: #333;
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
    .icon {
      width: 30px;
      height: 30px;
      margin: 15px 0px 15px 0px;
      display: inline-block;
      background: url('//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/contact.png') no-repeat;
      background-size: cover;
      vertical-align: middle;
      margin-right: 14px;
    }
    &:hover {
      color: #fff;
      border: unset;
      background-image: linear-gradient(to bottom right, #FF7252, #FF4D40);
      .icon {
        background: url('//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/contact_white.png') no-repeat;
        background-size: cover;
      }
    }
  }
  .contact_opacity {
    opacity: 0.5;
  }
}

.S .zy_header {
  height: rem(1080);
  .swiper {
    .swiper-pagination .swiper-pagination-bullet {
      width: rem(32);
      height: rem(32);
    }
  }
  .zy_slider_bg{
    background: url('//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_banner.a7a5f150.png') no-repeat center top;
    background-size: 100%;
    &.sec {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/banner_sec_phone.fc607629.png");
    }
    &.agent {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/enterprise_agent_banner_phone.063ac084.png");
    }
  }

  .contact {
    // width: rem(363);
    padding: 0px rem(48) 0px rem(39);
    height: rem(132);
    line-height: rem(132);
    right: rem(48);
    bottom: rem(60);
    .icon {
      width: rem(72);
      height: rem(72);
      margin: rem(30) rem(18) rem(30) 0;
    }
  }
}

.zy_slider_bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/banner.79c7d895.png");
  &.sec {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/banner_seco.5094a44c.png");
  }
  &.agent {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/enterprise_agent_banner.f966e523.png");
  }
}

.zy_slider_con {
  position: absolute;
  top: 152px;
  left: 287px;

  span {
    font-size: 80px;
  }
  p {
    font-size: 85px;
    color: #fff;
    line-height: 85px;
  }
}
</style>
