<template>
    <div id="culture" class="culture">
        <div class="culture_inner">
            <div class="culture_title">企业文化</div>
            <div class="culture_top">
                <div class="culture_img"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/culture.2062023c.png" alt=""></div>
                <div class="culture_rig">
                    <div class="culture_list" v-for="(item, index) in cultureList" :key="index">
                        <h1>{{item.title}}</h1>
                        <div class="culture_info">{{item.info}}</div>
                    </div>
                </div>
            </div>
            <div class="culture_title pt">掌阅范解读</div>
            <div class="zyunscramble">
                <div class="slider_box">
                    <div class="slider_item">
                        <div class="slider_icon"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/icon_must.7ff1eff1.png" alt=""></div>
                        <div class="slider_name">自驱敢为</div>
                        <dl>
                            <dt>对待自己：</dt>
                            <dd>渴望成功，有责任心</dd>
                            <dd>主动进取，突破舒适区</dd>
                            <dd>勇于担当，勇于表达</dd>
                            <dd>积极影响，打破定式</dd>
                        </dl>
                    </div>
                    <div class="slider_item">
                        <div class="slider_icon"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/icon_candid.5caf98df.png" alt=""></div>
                        <div class="slider_name">客观坦诚</div>
                        <dl>
                            <dt>对待他人：</dt>
                            <dd>深入本源，尊重事实</dd>
                            <dd>不自嗨，不包装</dd>
                            <dd>当面表达真实想法</dd>
                            <dd>言行一致</dd>
                        </dl>
                    </div>
                    <div class="slider_item">
                        <div class="slider_icon"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/icon_simple.e9b46d05.png" alt=""></div>
                        <div class="slider_name">简单高效</div>
                        <dl>
                            <dt>对待组织：</dt>
                            <dd>人际关系简单，没包袱</dd>
                            <dd>高效协同</dd>
                            <dd>流程规则简洁清晰</dd>
                            <dd>不冗余</dd>
                        </dl>
                    </div>
                    <div class="slider_item">
                        <div class="slider_icon"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/icon_perfection.e9dfde6e.png" alt=""></div>
                        <div class="slider_name">追求极致</div>
                        <dl>
                            <dt>对待事情：</dt>
                            <dd>不放过问题</dd>
                            <dd>更大范围找最优解</dd>
                            <dd>不断挑战更高标准</dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="culture_swiper">
                <swiper class="swiper" ref="mySwiper" :options="swiperOption">
                    <swiper-slide>
                        <div class="slider_item">
                            <div class="slider_icon"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/icon_must.7ff1eff1.png" alt=""></div>
                            <div class="slider_name">自驱敢为</div>
                            <dl>
                                <dt>对待自己：</dt>
                                <dd>渴望成功，有责任心</dd>
                                <dd>主动进取，突破舒适区</dd>
                                <dd>勇于担当，勇于表达</dd>
                                <dd>积极影响，打破定式</dd>
                            </dl>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slider_item">
                        <div class="slider_icon"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/icon_candid.5caf98df.png" alt=""></div>
                        <div class="slider_name">客观坦诚</div>
                        <dl>
                            <dt>对待他人：</dt>
                            <dd>深入本源，尊重事实</dd>
                            <dd>不自嗨，不包装</dd>
                            <dd>当面表达真实想法</dd>
                            <dd>言行一致</dd>
                        </dl>
                    </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slider_item">
                            <div class="slider_icon"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/icon_simple.e9b46d05.png" alt=""></div>
                            <div class="slider_name">简单高效</div>
                            <dl>
                                <dt>对待组织：</dt>
                                <dd>人际关系简单，没包袱</dd>
                                <dd>高效协同</dd>
                                <dd>流程规则简洁清晰</dd>
                                <dd>不冗余</dd>
                            </dl>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slider_item">
                            <div class="slider_icon"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/icon_perfection.e9dfde6e.png" alt=""></div>
                            <div class="slider_name">追求极致</div>
                            <dl>
                                <dt>对待事情：</dt>
                                <dd>不放过问题</dd>
                                <dd>更大范围找最优解</dd>
                                <dd>不断挑战更高标准</dd>
                            </dl>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
    </div>
</template>
<script>
import { cultureList } from "@/config/about";
export default {
    name:'Culture',
    data:function(){
        return{
            cultureList,
            swiperOption: {
                loop: true,
                speed: 1500,
                // slidesPerView: 1,
                autoplay: { delay: 5000 },
                spaceBetween: 0,
                slidesPerView:'auto',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
        }
    }
}
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.culture{
    background: linear-gradient(to bottom,#F7F7FA,rgba(247,248,250,0));
}
.culture_swiper{
    display: none;
}
.culture_inner{
    width: 992px;
    margin:0 auto;
}
.culture_title{
    padding-top:88px;
    font-size:32px;
    color:#333;
    &:nth-child(2){
        padding-top:80px;
    }
}
.culture_top{
    padding-top:47px;
    overflow: hidden;
    .culture_img{
        width: 631px;
        height: 367px;
        padding: 17px 0;
        float: left;
        img{width: 100%;}
    }
    .culture_info{
        padding-top:7px;
        font-size:16px;
        color:#333;
    }
    h1{
        padding:18px 0 9px;
        font-size:28px;
        color:#333;
    }
    .culture_list{
        &:first-child{
            background: url(//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/mission.ba8afbdb.png) no-repeat left top;
            background-size:278px 96px;
        }
        &:nth-child(2){
            padding:42px 0;
            background: url(//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/vision.5c05b45f.png) no-repeat left center;
            background-size:278px 96px;
        }
        &:last-child{
            background: url(//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/vision.5c05b45f.png) no-repeat left center;
            background-size:278px 96px;
        }
    }
    .culture_rig{
        margin-left:714px;
    }
}
.zyunscramble{
    padding:64px 0 88px;
    .slider_icon{
        width: 110px;
        height: 110px;
        margin: 0 auto;
        img{width: 100%;}
    }
    .slider_name{
        padding-top:24px;
        font-size:18px;
        text-align: center;
        color:#FC5649;
        font-family:"Heiti SC","黑体-简";
        font-weight: 500;
    }
    dl{
        margin: 0 auto;
        padding-top:12px;
        font-size:16px;
        color:#666;
        line-height: 28px;
    }
    .slider_item{
        width: 25%;
        float: left;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding:0 44px 0 40px;
    }
    .slider_box{
        overflow: hidden;
    }
}
.S{
    .culture_swiper{
        display: block;
        .slider_item{
            padding: rem(124) rem(128) rem(148);
            text-align: center;
            .slider_icon{
                width: rem(232);
                height: rem(232);
                margin:0 auto;
                img{width: 100%;}
            }
            .slider_name{
                padding:rem(64) 0 rem(24);
                font-size:rem(36);
                color:#FC5649;
            }
            dl{
                padding-bottom:rem(72);
                font-size:rem(33);
                color:#666;
                dt,dd{
                    display: inline-block;
                }
            }
        }
        .swiper-pagination{
            bottom:rem(124);
            .swiper-pagination-bullet{
                width: rem(21);
                height: rem(21);
                margin:0 rem(20);
                border:rem(3) solid #ccc;
                background: none;
                &.swiper-pagination-bullet-active{
                    background: #999;
                    border-color:#999;
                }
            }
        }
    }
    .zyunscramble{
        display: none;
    }
    .culture_title{
        padding:rem(148) rem(60) 0;
        font-size:rem(60);
        font-weight: bold;
        &.pt{
            padding-top:rem(80);
        }
    }
    .culture_top{
        padding:rem(80) rem(60) rem(50);
        background: #F7F8FA;
        .culture_img{
            padding:0;
            width: auto;
            height: auto;
            float:none;
        }
        .culture_rig{
            padding-left:rem(28);
            margin:rem(33) 0 0 0;
        }
        .culture_list{
            padding:rem(44) 0;
            &:first-child{
                padding-top:rem(30);
                background-position: left center;
                background-size:rem(305) rem(100)
            }
            &:nth-child(2){
                background-size:rem(240) rem(100)
            }
            &:nth-child(3){
                background-size:rem(230) rem(100)
            }
            h1{
                display: inline-block;
                width: rem(200);
                padding:0;
                font-size: rem(48);
            }
            .culture_info{
                padding-top: 0;
                display: inline-block;
                font-size:rem(36);
                color:#999;
            }
        }
    }
    .culture_inner{
        width:auto;
        padding:0;
    }
}
</style>