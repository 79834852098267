<template>
    <div id="zyBq" class="zybq">
        <div class="bq_banner">
            <img :src="`${efeUrl}/banner_bq.593ce0f3.png`" alt="" class="bqpc">
            <img :src="`${efeUrl}/bannerphone_bq.c07a2a88.png`" alt="" class="bqphone">
        </div>
        <div class="zybq_inner">
            <ul>
                <li>
                    <img :src="`${efeUrl}/q1.48f49cd3.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q2.24131791.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q3.33e9b04c.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q4.613f1286.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q5.59ecfb47.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q6.bb70760e.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q7.b1f0b9ae.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q8.eceb9f52.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q9.825dd8e6.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q10.f70c4ec3.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q11.5d7841c0.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q12.d9d9f2c2.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q13.b82d02cf.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q14.52da828b.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q15.3952b0ee.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q16.0ae0c6fc.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q17.0b24592d.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q18.1da69594.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q19.230093d8.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q20.8e69064f.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q21.fc466914.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q22.65b9ba90.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q23.d9bb404f.gif`" alt="">
                </li>
                <li>
                    <img :src="`${efeUrl}/q24.c3e9756a.gif`" alt="">
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import { efeUrl } from '@/utils/utils';
export default {
    data() {
        return {
            efeUrl
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zybq{margin-top: 82px;}
.bqphone{display: none;}
.bq_banner{
    text-align: center;
    img{
        width: 100%;
    }
}
.zybq_inner{
    width: 992px;
    margin: 0 auto;
    padding:62px 56px;
    box-sizing: border-box;
    li{
        width: 116px;
        height: 116px;
        list-style: none;
        padding:25px;
        float: left;
        img{
            width: 116px;
            height: 116px;
        }
    }
    ul{
        overflow: hidden;
    }
}
.S{
    .zybq{
        margin-top: rem(155);
    }
    .bqphone{
        display: block;
    }
    .bqpc{
        display: none;
    }
    .zybq_inner{
        width: auto;
        margin:0;
        padding:rem(103) rem(60);
        li{
            width: 25%;
            height: auto;
            padding: 0 0 rem(58);
            img{
                width: rem(180);
                height: rem(180);
            }
        }
        ul{
            padding: 0;
        }
    }
}
</style>