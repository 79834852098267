<template>
    <div id="honor" class="honor">
        <div class="honor_banner">
            <img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor_img.2b81bfed.png" alt="" class="honorpc_img">
            <img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/honor_phone_img.873e89aa.png" alt="" class="honorphone_img">
        </div>
        <div class="inner_honor" id="inner_honor">
            <div class="honor_slidebox" :style="getStyle()">
                <div class="honor_info" v-for="(honor,index) in honorList" :key="index">
                    <div class="honor_img"><img :src="honor.src" alt="" ondragstart="return false"></div>
                    <div class="honor_rig">
                        <div class="inner_position">
                            <h2>{{honor.honorName}}</h2>
                            <div class="img_form">{{honor.honorInfo}}</div>
                        </div>
                    </div>
                </div>
            </div>    
            <!--swiper class="honor_swiper" ref="mySwiper" :options="swiperOption">
                <swiper-slide  >
                    
                </swiper-slide>
            </swiper>
            <div class="slider_btn">
                <div class="swiper-button-prev" @click="prevBtn"></div>
                <div class="swiper-button-next" @click="nextBtn"></div>
            </div-->
        </div>
        <router-link class="zy_arrow_active" to="/allhonor">全部荣誉</router-link>
        <div class="phone_honor">
            <div class="honor_list" :class="flag?'honor_updown':''">
                <div class="honor_item" v-for="(item,index) in honorList" :key="index">
                    <div class="honor_img"><img  :src="item.src" alt=""></div>
                    <div class="honor_title">{{item.honorName}}</div>
                </div>
            </div>
            <div v-if="flag" class="more_box">
                <a class="text-blue" @click="flag = !flag">{{flag?'查看更多':''}}</a>
            </div>
        </div>
    </div>
</template>
<script>
import { honorList } from "@/config/about";
import { Mixins } from '@ireader/core-lib';
export default {
    name:'Honor',
    mixins: [Mixins.sdd()],
    data:function(){
        return{
            honorList: [],
            flag: false,
            marginLeft: 0,
            scrollContent: {
                contentWidth: 0,
                wrapWidth: 0,
                maxDistance: 0
            }
        }
    },
    async mounted() {
        this.flag = true
        const current = document.getElementById('inner_honor')
        if (current) {
            current.addEventListener('mousedown', this.onMouseDown, true)
            if (this.scrollCurrent) {
                const oSbar = this.scrollCurrent
                this.scrollContent = {
                    contentWidth: oSbar.scrollWidth,
                    wrapWidth: oSbar.offsetWidth,
                    maxDistance: oSbar.scrollWidth - oSbar.offsetWidth
                }
            }
        }
        try {
            const res = await this.$getSdd('zycom/honor_list')
            this.honorList = res || honorList;
        } catch (e) {
            this.honorList = honorList;
        }
    },
    destroyed() {
        const current = document.getElementById('inner_honor')
        if (current) {
            current.removeEventListener('mousedown', this.onMouseDown, true)
        }
    },
    computed: {
        scrollCurrent() {
            const swiperW = document.getElementById('inner_honor')
            if (swiperW && swiperW.getElementsByClassName('honor_slidebox') && swiperW.getElementsByClassName('honor_slidebox')[0]) {
                return swiperW.getElementsByClassName('honor_slidebox')[0]
            }
            return null
        },
    },
    methods: {
        getStyle() {
            return { marginLeft: `${this.marginLeft}px` }
        },
        isString(obj) {
            return typeof obj !== 'object'
        },
        onMouseDown(e) {
            const oEvent = e || e.event;
            const oSbar = this.scrollCurrent;
            const startX = oEvent.clientX || (oEvent.touches && oEvent.touches[0] && oEvent.touches[0].clientX);
            const startMarginLeft = oSbar.style.marginLeft ? Number(oSbar.style.marginLeft.slice(0, oSbar.style.marginLeft.length - 2)) :  0;

            const fnMove = (e) => {
                const oEvent = e || e.event;
                const endX = oEvent.clientX || (oEvent.touches && oEvent.touches[0] && oEvent.touches[0].clientX);
                let left = startMarginLeft + (endX - startX)
                left >= 0 && (left = 0);
                const { contentWidth, wrapWidth, maxDistance} = this.scrollContent;
                -left + wrapWidth >= contentWidth && (left = -maxDistance),
                this.marginLeft = left
            }
  
            const fnUp = () => {
                document.onmousemove = null;
                document.onmouseup = null;
                if(oSbar.releaseCapture)oSbar.releaseCapture();
            }
                
            if (oSbar.setCapture) {
                oSbar.onmousemove = fnMove;
                oSbar.onmouseup = fnUp;
                oSbar.setCapture();
            } else {
                document.onmousemove = fnMove;
                document.onmouseup = fnUp;
            }
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.phone_honor,.honorphone_img{display: none;}
.honor{
    .zy_arrow_active{
        display: block;
        padding: 64px 0 88px;
        text-align: center;
        font-size:14px;
        color:#FC5649;
    }
}
.inner_honor{
    position: relative;
    margin: 88px 0 0 224px;
    overflow-x: hidden;
    cursor: url('//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/cursor_swiper.png'),move;
    -ms-touch-action: none;
    touch-action: none;
    background: #F8F9FB;
    .honor_info{
        display: inline-block;
    }
    .honor_slidebox{
        width: 100%;
        white-space: nowrap;
    }
}
.honor_swiper{
    height: 380px;
    background: #F8F9FB;
}
h2{
    font-size:28px;
    color:#666;
    font-weight: normal;
    white-space: pre-wrap;
}
.img_form{
    padding-top:48px;
    font-size:16px;
    color:#666;
}
.honor_img{
    width: 506px;
    height: 380px;
    float: left;
    img{
        width: 100%;
    }
}
.inner_position{
    width: 390px;
    position: absolute;
    left:48px;
    top:50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.honor_rig{
    width: 580px;
    margin-left: 506px;
    position: relative;
    height: 380px;
}
// .slider_btn{
//     width: 47px;
//     height: 35px;
//     position:absolute;
//     left:772px;
//     top:284px;
//     background: url("../../../assets/images/aboutpage/swiper_btnbg.png") no-repeat center;
//     .swiper-button-prev{
//         width: 6px;
//         height: 10px;
//         margin-top: -6px;
//         background: url("../../../assets/images/aboutpage/prew.png") no-repeat center;
//     }
//     .swiper-button-next{
//         width: 6px;
//         height: 10px;
//         margin-top: -6px;
//         background: url("../../../assets/images/aboutpage/next.png") no-repeat center;
//     }
// }
.honor_banner{
    img{width: 100%;}
}
.S{
    .inner_honor,.zy_arrow_active,.honorpc_img{display: none;}
    .phone_honor,.honorphone_img{
        display: block;
    }
    .honor_img{
        width: rem(464);
        height: rem(348);
        overflow: hidden;
        float:none;
        margin: 0 auto;
        img{width: 100%;}
    }
    .honor_title{
        width: rem(464);
        padding: rem(32) 0 rem(40);
        background: #F9FAFC;
        font-size:rem(36);
        color:#333;
        margin: 0 auto;
        text-align: left;
    }
    .honor_item{
        float: left;
        width: 50%;
        height: rem(532);
        padding:rem(32) rem(32) 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .honor_list{
        padding:rem(48) rem(28);
        overflow: hidden;
        text-align: center;
        &.honor_updown{
           height: rem(2788);
           overflow: hidden; 
        }
    }
}
@media screen and (max-width:320px) {
    .S{
        .honor_img,.honor_title{
            width: rem(424);
        }
    }
}
</style>