<template>
  <div class="container">
    <img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/zycomlogo.png" style="position:absolute;left:-99em">
    <Bar :type="menuType"/>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { BAR_MENU_STATUS } from '@/config'
import Bar from "@/components/Bar";
import Footer from "@/components/Footer";
export default {
  name: "Home",
  components: {
    Bar,
    Footer
  },
  data() {
    return {
      menuType: BAR_MENU_STATUS.default
    }
  },
  mounted() {
    this.setMenuStatus();
  },
  methods: {
    setMenuStatus() {
      const { name } = this.$route;
      this.menuType = name === 'Home' ? BAR_MENU_STATUS.scroll : BAR_MENU_STATUS.default
    }
  },
  watch:{
    $route(){
      this.setMenuStatus();
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
body,
dd,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
  margin: 0;
}

a {
  color: inherit;
}

a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  text-decoration: none;
}

body {
  color: $mainDarkText;
}

.S body {
  overflow-x: hidden;
  overflow: scroll;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #202425;
  min-width: 1280px;
}
</style>