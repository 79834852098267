<template>
<div id="breadbox" class="breadEval">
    <div class="breadTitl">
        <span v-for="item in breadList" :key="item.path">
          <router-link :to="item.path">{{item.name}}</router-link>
        </span>
    </div>
</div>
</template>
<script>
export default {
    name:'Breadbox',
    computed: {
      breadList() {
        return this.$route.meta.breadList || [];
      }
    },
}

</script>
<style lang="scss">
.breadEval{
  position: sticky;
  top: 79px;
  height: 57px;
  line-height: 57px;
  background: #FBFBFB;
  box-shadow: 0 -1px 0 0 #f4f4f4 inset;
  z-index: 3;
}
.breadTitl{
  width: 992px;
  margin: 0 auto;
  font-size:16px;
  color:#999;
  span{
    position: relative;
    display: inline-block;
    padding-right:32px;
    margin-right:24px;
    &:after{
      content:'';
      position: absolute;
      right:0;
      top:50%;
      width: 8px;
      height: 22px;
      margin-top:-11px;
      background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/bread_bg.png") no-repeat right center;
      background-size:8px 22px;
    }
    &:last-child{
      color:#333;
      &:after{
        display: none;
      }
    }
    
  }
}
</style>