<template>
  <div id="banner" class="banner">
      <div class="banner_box">
        <img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/about_banner.e8eae53e.png" alt="" class="aboutpc_banner">
        <img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/about_phone_banner.f64402a2.png" alt="" class="aboutphone_banner">
      </div>
  </div>
</template>

<script>
//import { setting } from "@/config";
export default {
  name: "Banner"
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.banner {
  position: relative;
  margin-top: 82px;
  overflow: hidden;
}
.banner_box{
    img{
      width: 100%;display: block;
      &.aboutphone_banner{
        display: none;
      }
    }
}

.S{
  .banner_box {
    img{
      width: 100%;
      display: block;
      &.aboutphone_banne{
        display: block;
      }
      &.aboutpc_banner{
        display: none;
      }
    }
  }
  .banner{
    margin-top:rem(148);
  }
}

</style>
