<template>
    <div id="allreps" class="allreps">
        <div class="banner_reps"><img :src="`${efeUrl}/banner_resp.3dbf212a.png`" alt="" class="pc"><img :src="`${efeUrl}/banner_reps_phone.32615d8c.png`" alt="" class="phone_resp"></div>
        <Breadbox />
        <div class="reps_container">
            <div class="inner_reps">
                <div class="biglist">
                  <div class="bigtitle" id="resp1">
                      <h1>全民阅读 文化筑梦</h1>
                      <span>来源：掌阅科技</span>
                  </div>
                  <div class="content">为了更好地推广并号召全社会关注"全民阅读"，同时按照国家扶贫攻坚计划中通过文化教育扶贫一批的指示，助力实现中华民族伟大复兴的"中国梦"，掌阅科技于2018年在公司成立十周年之际，策划启动了"全民阅读∙文化筑梦"公益项目，为贫困地区、少数民族聚居区、革命老区等地区捐赠爱心阅读室，培养民众特别是青少年养成良好的阅读习惯，普及优秀的传统文化和高品质内容，扩展知识面，丰富精神生活，通过推广全民阅读，让更广泛地区、更多人群享受到阅读的乐趣，甚至摆脱贫困。</div>
                  <div class="img_box"><img :src="`${efeUrl}/reps_img1.bb6d60d4.png`" alt=""></div>
                  <div class="content mt24">该项目由书香中国·北京阅读季领导小组指导，掌阅联合新华社—中国经济信息社有限公司北京分公司共同主办，由权威出版机构、中央媒体等单位及掌阅文学作者共同参与。截至2021年5月，项目已先后在河北省康保县、河北省滦平县、内蒙古卓资县，内蒙古呼和浩特市辖内各旗县，四川省甘孜藏族自治州，以及红色革命圣地江西井冈山等地进行了捐赠，累计送出电子书阅读器350余台、电子书数十万余册、纸质书一万余册，书架文具若干，建成乡村爱心阅览室近90间。</div>
                  <div class="content mt24">在2020年中国数字阅读云上大会上，掌阅“全民阅读·文化筑梦”公益项目荣获“2019年度十佳阅读扶贫项目”。同时荣获第十届中国公益节“2020年度公益创新奖”。</div>
                  <div class="reps_con">
                    <div class="sub_tit">河北省承德市滦平县</div>
                    <div class="content mt24">素有"普通话之乡"之称的滦平县此前是国家级贫困县，也是人民日报海外版的定点扶贫县，学生学习条件艰苦，特别是缺少课外读物，阅读环境亟待改善。2018年世界读书日前夕，"全民阅读∙文化筑梦"公益项目在河北省承德市滦平县巴克什营镇古城川小学正式启动，将滦平县11所条件较差的小学校和教学点作为捐赠地，联合上海译文出版社、花城出版社、天地出版社、以及北京日知图书有限公司，以及掌阅文学爱心作者共同捐赠了电子书阅读器、电子书、纸质书、笔记本电脑、打印机、书架、文具等大量物资。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img2.ae23eb59.png`" alt=""></div>
                  </div>
                  <div class="reps_con">
                    <div class="sub_tit">内蒙古自治区呼和浩特市</div>
                    <div class="content mt24">内蒙古自治区呼和浩特市和林格尔县及周边地区教育资源匮乏，公共教育资源分配不均衡，以和林格尔县董家营小学为例，全校6个年级90名学生人均课外书不足5本，且内容老旧。2018年10月，项目团队与掌阅联合创始人王良先生、掌阅文学总编及爱心作者一起，为内蒙古呼和浩特市下辖的清水河县、和林格尔县、土默特左旗、托克托县、五川县5个旗县和赛罕区的10所小学进行了捐赠，包括电子书阅读器、纸质图书、书架及练习本等文具，共覆盖3027名蒙古族和汉族学生。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img3.3a131b0b.png`" alt=""></div>
                  </div>
                  <div class="reps_con">
                    <div class="sub_tit">河北省张家口市康保县</div>
                    <div class="content mt24">河北省张家口市康保县是国家扶贫开发重点县，2018年11月，掌阅公益响应北京市朝阳区委宣传部号召，向其定点扶贫地——河北省国家级贫困县康保县捐赠了40台电子书阅读器、电子书等物资，为当地10所贫困学校建立了电子阅览室。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img4.01709470.png`" alt=""></div>
                  </div>
                  <div class="reps_con">
                    <div class="sub_tit">四川省甘孜藏族自治州</div>
                    <div class="content mt24">四川省甘孜藏族自治州是国家深度贫困地区的“三区”之一。 “三区三州"深度贫困区80%以上区域位于青藏高原区，自然条件差、经济基础弱、贫困程度深，是我国脱贫攻坚的难中之难、坚中之坚任务之一。为打赢"三区三州"控辍保学扶贫攻坚战，2019年4月，掌阅公益积极参与中国音像与数字出版协会发起的公益活动，向四川省甘孜藏族自治州中小学捐赠了100台电子书阅读器，帮助当地建成电子阅览室30余间，助力文化教育扶贫，携手共筑精神文化家园。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img5.c0d7994c.png`" alt=""></div>
                  </div>
                  <div class="reps_con">
                    <div class="sub_tit">内蒙古自治区乌兰察布市卓资县</div>
                    <div class="content mt24">2018年11月，在朝阳区工商联举办的“万企帮万村”精准扶贫洽商会上，掌阅科技与内蒙古卓资县签订了意向帮扶协议，拟为当地10所贫困学校建立电子阅览室。2019年5月，掌阅党支部与掌阅公益团队先后两次赶赴内蒙古卓资县，履行职责，捐赠了10间爱心阅览室，包括40余台iReader电子书阅读器、数十万册电子书、2000余册纸质书、文具等物资。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img6.e5feed6a.png`" alt=""></div>
                  </div>
                  <div class="reps_con">
                    <div class="sub_tit">内蒙古自治区</div>
                    <div class="content mt24">2019年7月，公益项目携手人气演员王晓晨、知名作家果味喵、知名作家歌怨为内蒙自治区联合捐赠10间爱心阅读室。此次捐赠的物品包括40台iReader电子阅读器、5000余册纸质书、20000余本作业本以及若干文具。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img7.678fcf9c.png`" alt=""></div>
                  </div>
                  <div class="reps_con">
                    <div class="sub_tit">河北康保县和内蒙古卓资县</div>
                    <div class="content mt24">2021年初，为满足青少年学习热忱,营造先进学习环境,提供科技学习工具,掌阅公益行再次走进内蒙古自治区卓资县、河北省康保县,为两地两所学校60位贫困户学生捐赠了60台笔记本电脑,圆了孩子们一个电脑梦。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img8.79a70a6d.png`" alt=""></div>
                  </div>
                  <div class="reps_con">
                    <div class="sub_tit">革命圣地江西井冈山</div>
                    <div class="content mt24">2021年5月，在中国音像与数字出版协会指导下，掌阅联合江西人民出版社共同承办"2021全民阅读走进井冈山活动"，并进行了捐赠。其中，掌阅“全民阅读 文化筑梦”公益项目向井冈山小学、碧溪中心小学、碧溪三峰小学、拿山小学、井冈山实验学校捐赠了50台iReader电子书阅读器以及掌阅年度VIP畅读卡50张，每张含电子书20万册，免费畅读1年，以及200余册优质纸质图书。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img9.33d53e6b.png`" alt=""></div>
                  </div>
                </div>
                <div class="biglist">
                  <div class="bigtitle" id="resp2">
                      <h1>助残扶贫 居家就业</h1>
                      <span>来源：掌阅科技</span>
                  </div>
                  <div class="content">新中国成立 70 年来，党和国家十分关注特殊困难群体，残疾人的生活状况得到不断改善，社会地位显著提高。但如何更好地推动残疾人真正成为经济社会发展的参与者、贡献者、享有者，仍需要社会各界的不断努力。</div>
                  <div class="img_box"><img :src="`${efeUrl}/reps_img10.0946eaea.png`" alt=""></div>
                  <div class="content mt24">掌阅作书匠“助残扶贫·居家就业”公益项目是掌阅科技股份有限公司于2019年8月正式启动的。通过掌阅培训残障人士学习电子书的排版、校对和审核等技能，并提供相应岗位，使残障人士可以实现居家就业；不仅让其稳定获得一份收入，也让其接触到优秀的文化内容，丰富精神生活，最终达到物质和精神生活的双重提升。</div>
                  <div class="content mt24">该项目通过"互联网+就业"的模式，为残障人士实际解决就业问题，持续获取收入，在工作中获得尊严，实现自我价值，为社会做贡献。该公益项目不采取直接捐赠物资，而是提供以实际工作岗位为基础的针对性培训，培训通过即刻上岗，当月获得收入，且没有时间和地域限制，多劳多得。与此同时，大部分贫困地区的残障人士、或因残致贫的残疾人朋友由于身体原因，受教育程度普遍不高，在制作电子书过程中，不仅可以赚钱，还可以通过接触各类书籍，提升他们的文化素养，达到物质和精神生活的双重提升。</div>
                  <div class="content mt24">截止2021年6月，项目累计培训残疾人学员超过1800人，近300人在平台上获取收益，结算金额累计超过90万元，月人均收入超过1000元，一些优秀员工单月甚至达到5000元，不仅实现了脱贫，还超过了当地的大部分工作收入。</div>
                </div>
                <div class="biglist">
                  <div class="bigtitle" id="resp3">
                      <h1>助梦远航 心源计划</h1>
                      <span>来源：掌阅科技</span>
                  </div>
                  <div class="content">心源计划是面向于基层文学作者的一个梦想支持计划，寓意是爱心公益，源源不断。数据显示，我国网络文学行业有1400多万创作者，99%以上都是基层创作者，起始收入不高，如果遇到突发困难情况，创作之路很难坚持下去。为此，掌阅文化联合20多家平台与月关、蒋胜男、天蚕土豆等30多位知名作家共同发起“心源计划”，帮助基层作家解决重大困难（大病、天灾、车祸等），计划覆盖超过500万基层作家。心源计划的愿景是：立文学港湾，助梦想远航。截止到2020年9月，已帮助2个困难作家度过难关，活动共计10000余人参与捐款，累计捐款金额超过40万元。</div>
                  <div class="img_box"><img :src="`${efeUrl}/reps_img11.cd57bd4b.png`" alt=""></div>
                </div>
                <div class="biglist">
                  <div class="bigtitle" id="resp4">
                      <h1>掌阅公版</h1>
                      <span>来源：掌阅科技</span>
                  </div>
                  <div class="content">掌阅公版项目成立于2014年4月，致力于将传统文化书籍数字化，并佐以注释、翻译、插图、附录等，使原著更加丰富易懂，更有利于推广普及。此外，还单独推出“掌阅公版 启蒙”子项目，加入注音、朗读等效果，制作了一套更适合儿童阅读的公版图书。迄今上线书籍超400册，累计下载量逾1亿，读者好评率在95%以上。</div>
                  <div class="img_box"><img :src="`${efeUrl}/reps_img12.15c1239a.png`" alt=""></div>
                </div>
                <div class="biglist">
                  <div class="bigtitle">
                      <h1>其他项目</h1>
                      <span>来源：掌阅科技</span>
                  </div>
                  <div class="reps_con">
                    <div class="sub_tit"> “423一起读”全民公益活动</div>
                    <div class="content mt24">2019年世界读书日当天，结合联合国可持续发展目标“优质教育”，掌阅携手联合国、厦门航空打造了阅读主题联合梦想航班，同时在厦门、福州、杭州三地候机楼同步开展阅读活动，呼吁大家一起悦读，关注教育。<br><br>
在世界读书日前一天，掌阅联合中国铁路西安局集团有限公司、西安交通大学开展“乐享高铁 读书圆梦”主题公益读书活动，共同打造中国首个“阅读主题高铁列车”；同一天，掌阅联手中国铁路郑州局集团有限公司，共同打造了中国首个“阅读主题高铁车站”。<br><br>
无论是在主题航班、候机室，还是在高铁车站上，“阅读是成长最好的礼物”的公益活动都获得了旅客的认同和支持，许多旅客都为贫困山区儿童写下阅读鼓励卡，而这些鼓励卡在2019年5月17日由掌阅公益志愿者们连同电子书阅读器、纸质书、文具等物资，带到内蒙古乌兰察布盟卓资县，捐赠给那里的孩子们。</div>
                    <div class="img_box"><img :src="`${efeUrl}/reps_img13.95e15365.png`" alt=""></div>
                  </div>
                </div>
            </div>
            <!--手机端模板-->
            <div class="phone_container">
              <div class="inner_list">
                <div class="phone_title">掌阅公益简介</div>
                <div class="pagecon">掌阅成立至今，始终把"让阅读的价值无处不在"作为自己的使命，积极践行企业社会责任，策划执行了多项公益活动。特别是2018年至今，先后策划推出致力于改善农村及贫困地区青少年课外阅读内容匮乏、助力提升全民阅读的“全民阅读∙文化筑梦”社会公益项目，关爱作家群体、提供保障的“心源计划”，以及助力残疾人群体实现就业、摆脱贫困的“掌阅作书匠‘助残扶贫∙居家就业’公益项目”等。</div>
                <div class="pagecon">2020年初，为助力新冠疫情防治工作，公司在掌阅APP第一时间免费开放了新型冠病毒感染防护知识及多种防疫相关的书籍，并向湖北省人民医院和武汉大学中南医院两所医院共捐助200万元，用于湖北省疫情防治工作。同时，掌阅还为湖北读者免费提供了100万份掌阅APP月卡VIP，读者可免费畅读20万册图书一个月。</div>
              </div>
              <div class="inner_list">
                <div class="phone_title">全民阅读 文化筑梦</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img1.bb6d60d4.png`" alt=""></div>
                <div class="pagecon">为了更好地推广并号召全社会关注"全民阅读"，同时按照国家扶贫攻坚计划中通过文化教育扶贫一批的指示，助力实现中华民族伟大复兴的"中国梦"，掌阅科技于2018年在公司成立十周年之际，策划启动了"全民阅读∙文化筑梦"公益项目，为贫困地区、少数民族聚居区、革命老区等地区捐赠爱心阅读室，培养民众特别是青少年养成良好的阅读习惯，普及优秀的传统文化和高品质内容，扩展知识面，丰富精神生活，通过推广全民阅读，让更广泛地区、更多人群享受到阅读的乐趣，甚至摆脱贫困。</div>
                <div class="pagecon">该项目由书香中国·北京阅读季领导小组指导，掌阅联合新华社—中国经济信息社有限公司北京分公司共同主办，由权威出版机构、中央媒体等单位及掌阅文学作者共同参与。截至2021年5月，项目已先后在河北省康保县、河北省滦平县、内蒙古卓资县，内蒙古呼和浩特市辖内各旗县，四川省甘孜藏族自治州，以及红色革命圣地江西井冈山等地进行了捐赠，累计送出电子书阅读器350余台、电子书数十万余册、纸质书一万余册，书架文具若干，建成乡村爱心阅览室近90间。</div>
                <div class="pagecon">在2020年中国数字阅读云上大会上，掌阅“全民阅读 文化筑梦”公益项目荣获“2019年度十佳阅读扶贫项目”。同时荣获第十届中国公益节“2020年度公益创新奖”。</div>
                <div class="subtit">河北省承德市滦平县</div>
                <div class="pagecon">素有"普通话之乡"之称的滦平县此前是国家级贫困县，也是人民日报海外版的定点扶贫县，学生学习条件艰苦，特别是缺少课外读物，阅读环境亟待改善。2018年世界读书日前夕，"全民阅读∙文化筑梦"公益项目在河北省承德市滦平县巴克什营镇古城川小学正式启动，将滦平县11所条件较差的小学校和教学点作为捐赠地，联合上海译文出版社、花城出版社、天地出版社、以及北京日知图书有限公司，以及掌阅文学爱心作者共同捐赠了电子书阅读器、电子书、纸质书、笔记本电脑、打印机、书架、文具等大量物资。</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img2.ae23eb59.png`" alt=""></div>
                <div class="subtit">内蒙古自治区呼和浩特市</div>
                <div class="pagecon">内蒙古自治区呼和浩特市和林格尔县及周边地区教育资源匮乏，公共教育资源分配不均衡，以和林格尔县董家营小学为例，全校6个年级90名学生人均课外书不足5本，且内容老旧。2018年10月，项目团队与掌阅联合创始人王良先生、掌阅文学总编及爱心作者一起，为内蒙古呼和浩特市下辖的清水河县、和林格尔县、土默特左旗、托克托县、五川县5个旗县和赛罕区的10所小学进行了捐赠，包括电子书阅读器、纸质图书、书架及练习本等文具，共覆盖3027名蒙古族和汉族学生。</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img3.3a131b0b.png`" alt=""></div>
                <div class="subtit">河北省张家口市康保县</div>
                <div class="pagecon">河北省张家口市康保县是国家扶贫开发重点县，2018年11月，掌阅公益响应北京市朝阳区委宣传部号召，向其定点扶贫地——河北省国家级贫困县康保县捐赠了40台电子书阅读器、电子书等物资，为当地10所贫困学校建立了电子阅览室。</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img4.01709470.png`" alt=""></div>
                <div class="subtit">四川省甘孜藏族自治州</div>
                <div class="pagecon">四川省甘孜藏族自治州是国家深度贫困地区的“三区”之一。 “三区三州"深度贫困区80%以上区域位于青藏高原区，自然条件差、经济基础弱、贫困程度深，是我国脱贫攻坚的难中之难、坚中之坚任务之一。为打赢"三区三州"控辍保学扶贫攻坚战，2019年4月，掌阅公益积极参与中国音像与数字出版协会发起的公益活动，向四川省甘孜藏族自治州中小学捐赠了100台电子书阅读器，帮助当地建成电子阅览室30余间，助力文化教育扶贫，携手共筑精神文化家园。</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img5.c0d7994c.png`" alt=""></div>
                <div class="subtit">内蒙古自治区乌兰察布市卓资县</div>
                <div class="pagecon">2018年11月，在朝阳区工商联举办的“万企帮万村”精准扶贫洽商会上，掌阅科技与内蒙古卓资县签订了意向帮扶协议，拟为当地10所贫困学校建立电子阅览室。2019年5月，掌阅党支部与掌阅公益团队先后两次赶赴内蒙古卓资县，履行职责，捐赠了10间爱心阅览室，包括40余台iReader电子书阅读器、数十万册电子书、2000余册纸质书、文具等物资。</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img6.e5feed6a.png`" alt=""></div>
                <div class="subtit">内蒙古自治区</div>
                <div class="pagecon">2019年7月，公益项目携手人气演员王晓晨、知名作家果味喵、知名作家歌怨为内蒙自治区联合捐赠10间爱心阅读室。此次捐赠的物品包括40台iReader电子阅读器、5000余册纸质书、20000余本作业本以及若干文具。</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img7.678fcf9c.png`" alt=""></div>
                <div class="subtit">河北康保县和内蒙古卓资县</div>
                <div class="pagecon">2021年初，为满足青少年学习热忱,营造先进学习环境,提供科技学习工具,掌阅公益行再次走进内蒙古自治区卓资县、河北省康保县,为两地两所学校60位贫困户学生捐赠了60台笔记本电脑,圆了孩子们一个电脑梦。</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img8.79a70a6d.png`" alt=""></div>
                <div class="subtit">革命圣地江西井冈山</div>
                <div class="pagecon">2021年5月，在中国音像与数字出版协会指导下，掌阅联合江西人民出版社共同承办"2021全民阅读走进井冈山活动"，并进行了捐赠。其中，掌阅“全民阅读 文化筑梦”公益项目向井冈山小学、碧溪中心小学、碧溪三峰小学、拿山小学、井冈山实验学校捐赠了50台iReader电子书阅读器以及掌阅年度VIP畅读卡50张，每张含电子书20万册，免费畅读1年，以及200余册优质纸质图书。</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img9.33d53e6b.png`" alt=""></div>
              </div>
              <div class="inner_list">
                <div class="phone_title">助残扶贫 居家就业</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img10.0946eaea.png`" alt=""></div>
                <div class="pagecon">新中国成立 70 年来，党和国家十分关注特殊困难群体，残疾人的生活状况得到不断改善，社会地位显著提高。但如何更好地推动残疾人真正成为经济社会发展的参与者、贡献者、享有者，仍需要社会各界的不断努力。</div>
                <div class="pagecon">掌阅作书匠“助残扶贫·居家就业”公益项目是掌阅科技股份有限公司于2019年8月正式启动的。通过掌阅培训残障人士学习电子书的排版、校对和审核等技能，并提供相应岗位，使残障人士可以实现居家就业；不仅让其稳定获得一份收入，也让其接触到优秀的文化内容，丰富精神生活，最终达到物质和精神生活的双重提升。</div>
                <div class="pagecon">该项目通过"互联网+就业"的模式，为残障人士实际解决就业问题，持续获取收入，在工作中获得尊严，实现自我价值，为社会做贡献。该公益项目不采取直接捐赠物资，而是提供以实际工作岗位为基础的针对性培训，培训通过即刻上岗，当月获得收入，且没有时间和地域限制，多劳多得。与此同时，大部分贫困地区的残障人士、或因残致贫的残疾人朋友由于身体原因，受教育程度普遍不高，在制作电子书过程中，不仅可以赚钱，还可以通过接触各类书籍，提升他们的文化素养，达到物质和精神生活的双重提升。</div>
                <div class="pagecon">截止2021年6月，项目累计培训残疾人学员超过1800人，近300人在平台上获取收益，结算金额累计超过90万元，月人均收入超过1000元，一些优秀员工单月甚至达到5000元，不仅实现了脱贫，还超过了当地的大部分工作收入。</div>
              </div>
              <div class="inner_list">
                <div class="phone_title">助梦远航 心源计划</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img11.cd57bd4b.png`" alt=""></div>
                <div class="pagecon">心源计划是面向于基层文学作者的一个梦想支持计划，寓意是爱心公益，源源不断。数据显示，我国网络文学行业有1400多万创作者，99%以上都是基层创作者，起始收入不高，如果遇到突发困难情况，创作之路很难坚持下去。为此，掌阅文化联合20多家平台与月关、蒋胜男、天蚕土豆等30多位知名作家共同发起“心源计划”，帮助基层作家解决重大困难（大病、天灾、车祸等），计划覆盖超过500万基层作家。心源计划的愿景是：立文学港湾，助梦想远航。截止到2020年9月，已帮助2个困难作家度过难关，活动共计10000余人参与捐款，累计捐款金额超过40万元。</div>
              </div>
              <div class="inner_list">
                <div class="phone_title">掌阅公版</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img12.15c1239a.png`" alt=""></div>
                <div class="pagecon">掌阅公版项目成立于2014年4月，致力于将传统文化书籍数字化，并佐以注释、翻译、插图、附录等，使原著更加丰富易懂，更有利于推广普及。此外，还单独推出“掌阅公版 启蒙”子项目，加入注音、朗读等效果，制作了一套更适合儿童阅读的公版图书。迄今上线书籍超400册，累计下载量逾1亿，读者好评率在95%以上。</div>
              </div>
              <div class="inner_list">
                <div class="phone_title">其他项目</div>
                <div class="phone_img"><img :src="`${efeUrl}/reps_img13.95e15365.png`" alt=""></div>
                <div class="subtit"> “423一起读”全民公益活动 </div>
                <div class="pagecon">2019年世界读书日当天，结合联合国可持续发展目标“优质教育”，掌阅携手联合国、厦门航空打造了阅读主题联合梦想航班，同时在厦门、福州、杭州三地候机楼同步开展阅读活动，呼吁大家一起悦读，关注教育。</div>
                <div class="pagecon">在世界读书日前一天，掌阅联合中国铁路西安局集团有限公司、西安交通大学开展“乐享高铁 读书圆梦”主题公益读书活动，共同打造中国首个“阅读主题高铁列车”；同一天，掌阅联手中国铁路郑州局集团有限公司，共同打造了中国首个“阅读主题高铁车站”。</div>
                <div class="pagecon">无论是在主题航班、候机室，还是在高铁车站上，“阅读是成长最好的礼物”的公益活动都获得了旅客的认同和支持，许多旅客都为贫困山区儿童写下阅读鼓励卡，而这些鼓励卡在2019年5月17日由掌阅公益志愿者们连同电子书阅读器、纸质书、文具等物资，带到内蒙古乌兰察布盟卓资县，捐赠给那里的孩子们。</div>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadbox from "@/components/Breadbox";
import { setting } from "@/config";
import { efeUrl } from "@/utils/utils";
export default {
  name: "allreps",
  data: function() {
    const { resp } = setting;
    const { respList } = resp;
    return {
      efeUrl,
      resp, respList,
      offSet: 0, // 锚点位置相对于原位置的偏移量
      fixedHeader: true, // 是否存在固定页头
      headerHeight: 80, // 页头高度
      stepHeight: 60, //分布定位每次渲染移动多少像素
      activeAnchor: "", // 当前定位的锚点
      anchorPosition: [], //存放各锚点位置
      scrollTop: 0,
    };
  },
  components: {
      Breadbox,
  },
  mounted: function() {
    let id = this.$route.query.id

    if(id){
      this.toAnchor(id,true)
    }
    this.initPosition();
  },
  destroyed: function() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    initPosition() {
      if (this.anchorList && this.anchorList.length === 0) {
        return;
      }
      // 如果为动态锚点，自动定位当前所属锚点
      window.addEventListener("scroll", this.onScroll, true);
      this.onScroll();
      this.activeAnchor = null;
      this.anchorPosition = [];
    },
    onScroll() {
      if (this.anchorList && this.anchorList.length === 0) {
        return;
      }
      if (this.anchorList && this.anchorPosition.length === 0) {
        this.anchorList.forEach(anchor => {
          const toElement = document.querySelector("#" + anchor.id);
          if (toElement) {
            this.anchorPosition.push({
              id: anchor.id,
              position:
                toElement.offsetTop +
                (this.fixedHeader ? -this.headerHeight : this.headerHeight)
            });
          }
        });
      }
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrolled;
      scrolled = Math.ceil(scrolled);
      // 将锚点位置列表根据锚点位置排序
      this.anchorPosition.sort(function(pre, next) {
        return pre.position - next.position;
      });
      // 根据页面位置进行当前锚点高亮
      for (let i = 0; i < this.anchorPosition.length - 1; i++) {
        if (
          scrolled >= this.anchorPosition[i].position &&
          scrolled < this.anchorPosition[i + 1].position
        ) {
          this.activeAnchor = this.anchorPosition[i].id;
        }
      }
      if (this.anchorPosition.length === 0) {
        return;
      }
      if (
        scrolled >= this.anchorPosition[this.anchorPosition.length - 1].position
      ) {
        this.activeAnchor = this.anchorPosition[
          this.anchorPosition.length - 1
        ].id;
      }
    },
    toAnchor(anchor,flag) {
      let id = '';
      // flag 是判断从首页关于我们进来时用的
      if(flag){
        id = anchor
      }else{
        id = anchor.id;
        if (anchor.clickEvent && !anchor.clickEvent()) {
          return;
        }
      }
      this.activeAnchor = id;
      const toElement = document.querySelector("#" + id);
      if (toElement) {
        const headerHeight = this.fixedHeader
          ? -this.headerHeight
          : this.headerHeight;
        const scrollTop = toElement.offsetTop + this.offSet + headerHeight;
        if (scrollTop === document.documentElement.scrollTop) {
          return;
        }
        this.changeMenu();
        scrollTo(0, scrollTop);
      }
    },
    changeMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.allreps{margin-top: 82px;}
.banner_reps{height:279px;text-align: center;}
.reps_container{
  width: 992px;
  margin: 0 auto;
}
.bigtitle{
  position: relative;
  padding-bottom:24px;
  border-bottom:1px solid #E6E6E6;
  margin-bottom: 48px;
  h1{
    line-height: 48px;
    font-size:28px;
    color:#333;
    font-weight: normal;
  }
  span{
    padding-top:12;
    font-size:14px;
    color:#999;
  }
}
.sub_tit{
  margin-top:40px;
  font-size:16px;
  color:#333;
}
.content{
  line-height: 1.9;
  font-size:16px;
  color:#666;
  &.mt24{
    margin-top: 24px;
  }
}
.img_box{
  margin-top:24px;
}
.biglist{
  margin-bottom: 88px;
}
.inner_reps{
  padding:88px 106px 0;
}
.phone_resp,.phone_container{display: none;}

.S {
  .allreps{
    margin-top: rem(157);
  }
  .banner_reps{
    height: auto;
    img{width:100%}
  }
  .reps_container{width: auto;}
  .pc,.inner_reps,.breadEval{
    display: none;
  }
  .phone_resp,.phone_container{
    display: block;
  }
  .inner_list{
    margin-top:rem(116);
    &:last-child{
      margin-bottom: rem(116);
    }
  }
  .phone_title{
    width: rem(620);
    height: rem(148);
    line-height: rem(148);
    padding-left:rem(60);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: linear-gradient(145deg,#F7F8FA,rgba(247,248,250,0));
    font-size:rem(60);
    font-weight: bold;
    color:#333;
  }
  .pagecon{
    line-height: 1.9;
    padding: rem(48) rem(60) 0 rem(60);
    font-size:rem(42);
    color:#666;
  }
  .phone_img{
    padding: rem(48) rem(60) 0;
    img{width: 100%;}
  }
  .subtit{
    padding: rem(80) rem(60) 0;
  }
}
</style>