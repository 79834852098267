<template>
    <div id="copyright" class="copyright">
        <div class="inner_con">
            <h1>版权声明</h1>
            <div class="subcon">
                掌阅网及掌阅客户端部分作品来源用户上传，掌阅不曾对用户所传内容作任何形式的编辑与修改，如有发现用户上传作品有侵犯他人权利行为的，请及时与掌阅联系。
            </div>
            <div class="subcon">掌阅网及掌阅客户端坚决反对任何违反中华人民共和国有关著作权的法律法规的行为，秉承高度重视知识产权保护并遵守中华人民共和国各项知识产权法律、法规和具有约束力的规范性文件的态度,坚信著作权拥有者的合法权益应该得到尊重和依法保护。</div>
            <div class="subcon">然由于我们只作为转播平台为用户提供服务，无法对用户上传的所有作品内容进行充分调查监测，因此当著作权人或依法可以行使著作权的权利人（权利人）发现掌阅网及掌阅客户端的附件内容侵犯其著作权时，权利人应事先向我公司发出“权利通知”,掌阅网及掌阅客户端将根据中国法律法规和政府规范性文件采取措施移除相关内容或屏蔽相关链接。</div>
            <div class="subcon">如果您认为掌阅网或掌阅客户端上传的某一内容或资料被不恰当公布，您可以要求掌阅网、掌阅客户端删除该内容或资料；但必须向掌阅网、掌阅客户端提供以下材料，否则该要求将被视为未提出：
                <p>（1）著作权人身份证明文件；</p>
                <p>（2）著作权权属证明；</p>
                <p>（3）被侵权内容在网站中所处位置或链接；</p>
                <p>（4）您的联系方式，包括但不限于地址、电话和电子邮箱；</p>
                <p>（5）您表示该内容或资料的使用未经版权所有人或其代理人/许可人的合法授权的声明；</p>
                <p>（6）法律明文要求的其他资料</p>
            </div>
            <div class="subcon">
                一旦收到符合上述要求条款资料，掌阅将及时将您的作品下架或者删除。
            </div>
            <div class="subcon">已上传作品的用户视为同意上述措施。掌阅不因此承担任何违约责任或其他任何法律责任，包括不承担因侵权指控不成立而给原上传用户带来损害的赔偿责任。</div>
            <div class="subcon">本协议未涉及的问题参见国家有关法律法规，当本协议与国家法律法规冲突时，以国家法律法规为准。</div>
            <div class="subcon">掌阅网及掌阅客户端所收录作品及作品相关评论，与掌阅立场无关！</div>
        </div>    
    </div>
</template>
<script>
export default {
    name:'thems'
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.copyright{
    margin-top: 82px;
}
.subcon{
    margin-bottom: 24px;
    line-height: 1.9;
    font-size: 16px;
    color:#666;
    p{
        margin-top:16px;
    }
}
.inner_con{
    width: 780px;
    padding: 88px 0;
    margin:0 auto;
    h1{
        line-height: 48px;
        text-align: center;
        font-size: 28px;
        color: #333;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E6E6E6;
        font-weight: normal;
    }
}
.S{
    .copyright{
        margin-top:rem(148);
    }
    .inner_con{
        width:auto;
        padding:rem(92) rem(60) rem(124);
        h1{
            font-size:rem(60);
            color:#333;
            font-weight: normal;
            padding-bottom: rem(56);
            margin-bottom:rem(72)
        }
        .first_con{
            font-size:rem(42);
        }
        .sub_title{
            font-size:rem(48)
        }
        .subcon{
            margin-top:rem(48);
            margin-bottom: 0;
            font-size: rem(42);
            p{
                margin-top:rem(40);
                font-size: rem(42);
            }
        }
    }
}
</style>