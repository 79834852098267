<template>
  <div id="zyPage" class="zy_page">
    <Header />
    <component v-for="nav in navList" :key="nav.href" :is="nav.component"></component>
  </div>
</template>

<script>
import { nav } from "@/config";
import Header from "./components/Header";
import About from "./components/About";
import Brand from "./components/Brand";
import Copyright from "./components/Copyright";
import Business from "./components/Business";
import News from "./components/News";
import Invest from "./components/Invest";
import Resp from "./components/Resp";

export default {
  name: "Home",
  components: {
    Header,
    About,
    Brand,
    Copyright,
    Business,
    News,
    Invest,
    Resp
  },
  data: function() {
    return {
      navList: nav, // 导航列表
      rememberOffsetTop: 0
    };
  },
  beforeRouteLeave(to,from,next){
  ///关键语句
    let top= document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
    this.rememberOffsetTop =top|| 0
    sessionStorage.setItem('rememberOffsetTop',this.rememberOffsetTop) 
    next();
  },
  mounted(){
    setTimeout(() => {
      document.documentElement.scrollTop = Number(sessionStorage.getItem('rememberOffsetTop') )
    }, 20);
  },

};
</script>

<style lang="scss">
@keyframes fadeInDown {
  from {
    opacity: 0.05;
    transform: translate3d(0, -360px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0.05;
    transform: translate3d(0, -360px, 0);
  }
}

.fade-down-enter-active {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
}

.fade-down-leave-active {
  animation-name: fadeOutUp;
  animation-duration: 0.3s;
}
</style>
