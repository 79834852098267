//更多动态（全部新闻列表）

const allnewsList = [
  {
    date: '2023',
    list: [
      {
        title: '中国“网络文学+”大会优秀作品公布，掌阅科技原创作品获提名',
        date: '2023.03.27',
        href: 'https://news.sina.com.cn/sx/2023-03-27/detail-imynhqve1863993.shtml#/'
      },
      {
        title: '第六届中国“网络文学+”大会启幕，掌阅科技融合出版成果引关注',
        date: '2023.03.27',
        href: 'http://culture.ynet.com/2023/03/27/3603813t467.html'
      },
      {
        title: '科技赋能+互动体验，第六届中国“网络文学+”大会带来沉浸式“观展”',
        date: '2023.03.24',
        href: 'https://bj.bjd.com.cn/5b165687a010550e5ddc0e6a/contentShare/5b21d73be4b02439500383f1/AP641db795e4b03a6b6edd8206.html'
      },
      {
        title: '掌阅科技多部融合出版网络文学作品亮相中国“网络文学+”大会',
        date: '2023.03.24',
        href: 'https://news.sina.com.cn/sx/2023-03-24/detail-imymycyf9755124.shtml'
      },
      {
        title: '终端技术发展，阅读体验变革，掌阅科技亮相中国“网络文学+”大会',
        date: '2023.03.24',
        href: 'https://qiye.chinadaily.com.cn/a/202303/24/WS641d6fb7a3102ada8b235325.html'
      },
      {
        title: '潮声丨真的是“为爱发电”？一篇网文为何能连载十多年',
        date: '2023.03.23',
        href: 'https://tianmunews.com/news.html?id=2426491&from_channel=63e60cc814892e00016b1592&top_id=2426498'
      },
      {
        title: '网络文学十年： 优秀作品书写时代巨变，崭新气象彰显创造活力',
        date: '2023.03.22',
        href: 'https://bj.bjd.com.cn/5b165687a010550e5ddc0e6a/contentShare/5b21d73be4b02439500383f1/AP641aeb4de4b0e0511d883b0f.html'
      },
      {
        title: '掌阅科技斩获第六届iDigital年度数字营销大赏内容营销大奖',
        date: '2023.03.21',
        href: 'http://biz.jrj.com.cn/2023/03/21101137415520.shtml'
      },
      {
        title: '掌阅科技旗下掌阅iReader发布智能阅读本iReader Ocean 3',
        date: '2023.03.20',
        href: 'http://news.ikanchai.com/2023/0320/531737.shtml'
      },
      {
        title: '第八届中华优秀出版物公布 《西海固笔记》入选',
        date: '2023.03.08',
        href: 'https://share.gmw.cn/reader/2023-03/08/content_36416583.htm'
      },
      {
        title: '全民阅读推进成果丰硕，掌阅科技助力丰富群众精神文化生活',
        date: '2023.03.07',
        href: 'https://news.bjd.com.cn/2023/03/07/10357775.shtml'
      },
      {
        title: '北京阅读季“阅读+”对话，分享数字阅读推广新经验',
        date: '2023.03.07',
        href: 'https://bj.bjd.com.cn/5b165687a010550e5ddc0e6a/contentShare/5b21d73be4b02439500383f1/AP6407376fe4b0e0511d87c29f.html'
      },
      {
        title: '北京阅读季“阅读+”论坛开讲，掌阅精选以阅读助力企业飞跃发展',
        date: '2023.03.02',
        href: 'https://www.cet.com.cn/wzsy/cyzx/3332491.shtml'
      },
      {
        title: '网络文学大咖谈丨马艳霞：网文有望成为中国文化海外传播主力军',
        date: '2023.03.02',
        href: 'https://hn.rednet.cn/content/646747/94/12398068.html'
      },
      {
        title: '《铁骨铮铮》入选2021年度中国网络文学影响力榜',
        date: '2023.03.02',
        href: 'https://culture.yunnan.cn/system/2023/03/02/032489239.shtml'
      },
      {
        title: '《重生:湘江战役失散红军记忆》入选“优秀现实题材和历史题材网络文学出版工程”',
        date: '2023.03.02',
        href: 'https://share.gmw.cn/reader/2023-03/02/content_36401867.htm'
      },
      {
        title: '数字化时代如何推广全民阅读？业内大咖各抒己见',
        date: '2023.03.01',
        href: 'https://m.mp.oeeee.com/a/BAAFRD000020230228768785.html'
      },
      {
        title: '北京阅读季第3场对话：业内人士分享数字阅读推广及应用新经验',
        date: '2023.03.01',
        href: 'https://app.bjtitle.com/8816/newshow.php?newsid=6310559&typeid=1&did=a8f6b48b302044c681a65f8ef78541e3&ff=fz&uid=332655&show=0&fSize=M&ver=3.1.0&mood=wx'
      },
      {
        title: '聚焦数字阅读推广及应用新经验 阅读盛典第3场“阅读+”对话论坛举办',
        date: '2023.03.01',
        href: 'https://wap.peopleapp.com/article/7019014/6875108#ocr'
      },
      {
        title: '掌阅科技：将接入百度文心一言 成为首批体验官',
        date: '2023.02.15',
        href: 'http://egs.stcn.com/news/detail/1369919.html'
      },
      {
        title: '《Le Consentement》（《同意》）中文版出版，电子书独家上线掌阅',
        date: '2023.02.10',
        href: 'http://culture.ynet.com/2023/02/10/3587441t467.html'
      },
      {
        title: '掌阅科技《舌尖上的华尔兹》斩获“最江南”主题网络文学征文大奖',
        date: '2023.02.03',
        href: 'https://finance.ifeng.com/c/8N6JY4s7vrY'
      },
      {
        title: '粮食生产稳定发展，掌阅科技现实题材作品《粮战》致敬一代育粮人',
        date: '2023.01.31',
        href: 'https://china.qianlong.com/2023/0131/7965053.shtml'
      },
      {
        title: '掌阅科技再获脉脉MAX年度“职得去”优秀雇主企业',
        date: '2023.01.30',
        href: 'https://news.sina.com.cn/sx/2023-01-30/detail-imycyieq7087386.shtml'
      },
      {
        title: '掌阅科技2022年度原创文学获奖作品推荐',
        date: '2023.01.16',
        href: 'https://china.qianlong.com/2023/0116/7956673.shtml'
      },
      {
        title: '《大明第一狂士》入选中国小说学会“2022年度好小说”榜单',
        date: '2023.01.09',
        href: 'https://reader.gmw.cn/2023-01/10/content_36291015.htm'
      },
    ]
  },
  {
        date: '2022',
        list: [
          {
            title: '掌阅科技发布2022年度“风帆好书榜”',
            date: '2022.12.30',
            href: 'http://dzzy.cbbr.com.cn/html/2022-12/30/content_55909_15659432.htm'
          },
          {
            title: '用最美的文字告慰最好的自己，2022年度掌阅“风帆好书榜”发布',
            date: '2022.12.26',
            href: 'http://www.cbbr.com.cn/contents/533/82680.html'
          },
          {
            title: '掌阅科技入选2022年度北京服务业企业100强、数字经济企业100强榜单',
            date: '2022.12.23',
            href: 'https://finance.ifeng.com/c/8LyHSrqdzY6'
          },
          {
            title: '2022年度掌阅“风帆好书榜”发布：用最美的文字告慰最好的自己',
            date: '2022.12.23',
            href: 'http://www.chinawriter.com.cn/n1/2022/1223/c403994-32592743.html'
          },
          {
            title: '好书单来了！2022年度掌阅“风帆好书榜”发布',
            date: '2022.12.22',
            href: 'https://bj.bjd.com.cn/5b165687a010550e5ddc0e6a/contentShare/5b21d73be4b02439500383f1/AP63a4273ce4b0450519f68f03.html'
          },
          {
            title: '近百部作品入选2022年度“风帆好书榜”，体现“全民阅读”最新成果',
            date: '2022.12.21',
            href: 'https://www.ccmapp.cn/ccmapp3.0/index.html#/shareDetail?action=opendetail%3Brichtext%3Bf1ff109c-7384-46a9-91aa-bffce10b2c33&terminalid=undefined&siteId=1'
          },
          {
            title: '掌阅科技发布2022年度“风帆好书榜',
            date: '2022.12.21',
            href: 'https://www.chinaxwcb.com/info/584574'
          },
          {
            title: '国内数字阅读行业推出2022年度推荐好书榜 一起来阅读吧',
            date: '2022.12.21',
            href: 'https://zj.zjol.com.cn/news.html?id=1979070'
          },
          {
            title: '掌阅科技旗下掌阅iReader发布智能记事本iReader Smart Air',
            date: '2022.12.11',
            href: 'https://finance.ifeng.com/c/8LenpRw4ret'
          },
          {
            title: '《重生：湘江战役失散红军记忆》入选2021年“优秀现实题材和历史题材网络文学出版工程”',
            date: '2022.11.28',
            href: 'https://reader.gmw.cn/2022-11/28/content_36194000.htm'
          },
          {
            title: '聚焦原创IP，掌阅科技作品《蓝月纪元》首发数字藏品',
            date: '2022.11.25',
            href: 'http://news.ikanchai.com/2022/1125/513576.shtml'
          },
          {
            title: '掌阅科技作品《绝世武魂》斩获第三届泛华文网络文学金键盘奖',
            date: '2022.11.10',
            href: 'https://finance.ifeng.com/c/8KnnglWOyGQ'
          },
          {
            title: '非凡十年 首善答卷 | 书香京城：厚积全国文化中心底蕴',
            date: '2022.10.21',
            href: 'https://bjrbdzb.bjd.com.cn/bjrb/mobile/2022/20221021/20221021_007/content_20221021_007_1.htm?storyId=AP6351b8cae4b0180217452353&userID=634f941be4b0adf169519514,1&isshare=1&channelId=newspaperBjrb&columnId=5f094d2ce4b086b26e06513f&contentType=12&isBjh=0'
          },
          {
            title: '企业家看二十大 | 掌阅张凌云：以科技促文化助力书香社会建设',
            date: '2022.10.18',
            href: 'http://m.bjnews.com.cn/detail/1666088347169076.html'
          },
          {
            title: '北京河南干部群众奋斗实干迎盛会',
            date: '2022.10.16',
            href: 'http://paper.ce.cn/pc/content/202210/16/content_262670.html'
          },
          {
            title: '掌阅科技：从“让阅读无处不在”到“让阅读价值无处不在”',
            date: '2022.10.13',
            href: 'http://data.chinaxwcb.com/epaper2022/epaper/d7821/d4b/202210/128758.html'
          },
          {
            title: '【领航中国】数字出版：融合发展 蒸蒸日上',
            date: '2022.10.11',
            href: 'https://epaper.gmw.cn/gmrb/html/2022-10/11/nw.D110000gmrb_20221011_3-01.htm'
          },
          {
            title: '国家知产领域最高荣誉，掌阅科技入选2022年度国家知识产权示范企业',
            date: '2022.09.28',
            href: 'http://news.ikanchai.com/2022/0928/500982.shtml'
          },
          {
            title: '掌阅iReader数字阅读平台入选2022年度“数字出版优质平台遴选计划”',
            date: '2022.09.26',
            href: 'http://cn.chinadaily.com.cn/a/202209/26/WS63316d14a310817f312efdca.html'
          },
          {
            title: '第二届“掌阅精选企业阅读节”举办，聚焦降本增效推广企业阅读',
            date: '2022.09.09',
            href: 'https://bj.bjd.com.cn/5b165687a010550e5ddc0e6a/contentShare/5b21d73be4b02439500383f1/AP631b1eb5e4b07e590dc3bddb.html'
          },
          {
            title: '第二届“掌阅精选企业阅读节”举办，聚焦降本增效推广企业阅读',
            date: '2022.09.09',
            href: 'http://web.app.workercn.cn/news.html?id=240775'
          },
          {
            title: '破解企业阅读难题 数字新技术如何生动呈现文化内容？',
            date: '2022.09.09',
            href: 'https://m.chinanews.com/wap/detail/chs/zw/9849297.shtml'
          },
          {
            title: '掌阅科技全新升级企业阅读服务方案，推出“掌阅精选企业会员”',
            date: '2022.09.08',
            href: 'https://news.sina.com.cn/sx/2022-09-08/detail-imqmmtha6521148.shtml'
          },
          {
            title: '文化赋能科技，促进消费升级，掌阅科技参展2022服贸会',
            date: '2022.09.06',
            href: 'https://news.sina.com.cn/sx/2022-09-06/detail-imqmmtha6146888.shtml'
          },
          {
            title: '人均单日时长突破两小时，掌阅科技致力推动阅读进入“全民时代”',
            date: '2022.08.30',
            href: 'https://news.sina.com.cn/sx/2022-08-30/detail-imizmscv8295681.shtml'
          },
          {
            title: '掌阅科技加快构建海外市场本地化内容生态，推动文化“走出去”',
            date: '2022.08.30',
            href: 'http://biz.jrj.com.cn/2022/08/30112136951754.shtml'
          },
          {
            title: '掌阅科技IP微短剧成绩单｜42部作品，播放总量超10亿',
            date: '2022.08.28',
            href: 'https://mp.weixin.qq.com/s?__biz=MzIzODQ4Njg0Nw==&mid=2247505084&idx=2&sn=57e3d59d5ac9d168e722b4e680825380&chksm=e93a00ecde4d89fa0aa207aa3d7d3a833abe7c5a651b93a65d89f789554f795f1181ed38afa0&token=1049780638&lang=zh_CN#rd'
          },
          {
            title: '掌阅科技原创文学《蔚蓝盛宴》获批2022年北京宣传文化引导基金',
            date: '2022.08.27',
            href: 'https://finance.ifeng.com/c/8InS6lJHI67'
          },
          {
            title: '掌阅科技探索“先电后纸”出新作，月关《逍遥游》出版实体书',
            date: '2022.08.11',
            href: 'https://www.cet.com.cn/itpd/itxw/3221635.shtml'
          },
          {
            title: '一探月关笔下的贞观盛世，掌阅科技原创文学《逍遥游》出版实体书',
            date: '2022.08.05',
            href: 'https://mp.weixin.qq.com/s?__biz=MzIzODQ4Njg0Nw==&mid=2247505019&idx=1&sn=9b35d604b39e587856d8d829ff9229ca&chksm=e93a002bde4d893d1ff1ccd393919bfd395198cdc483cea50e126e2f44bf2453b0ced1180d7a&token=1049780638&lang=zh_CN#rd'
          },
          {
            title: '胡同博物馆内“阅读再出发” 北京阅读季读书沙龙开幕',
            date: '2022.07.23',
            href: 'https://m.thecover.cn/news_details.html?from=iosapp&id=9475357&channelId=null&userId=not_login'
          },
          {
            title: '掌阅科技《重生：湘江战役失散红军记忆》斩获2022年数字阅读推荐作品',
            date: '2022.07.20',
            href: 'https://mp.weixin.qq.com/s?__biz=MzIzODQ4Njg0Nw==&mid=2247504964&idx=1&sn=82323f6018eb2e5b7444c8f9bc3c9769&chksm=e93a0014de4d8902ebece9ccd3289e12722554e4dc3c953075a86c8bc1e38bb56dfeac6519cf&token=1049780638&lang=zh_CN#rd'
          },
          {
            title: '掌阅科技当选“中国专利保护协会”理事单位',
            date: '2022.07.15',
            href: 'https://mp.weixin.qq.com/s?__biz=MzIzODQ4Njg0Nw==&mid=2247504937&idx=1&sn=bbd510606b77cfa9c5bb8334b4882f33&chksm=e93a0079de4d896fe43cb874f88f7844bb44f82885b532498c3a54fcc8fdeb6d3c40ed87157b&token=1049780638&lang=zh_CN#rd'
          },
          {
            title: '电子阅读器使用情况独家调查  用户数字化阅读习惯已深刻迭代',
            date: '2022.07.15',
            href: 'http://dzzy.cbbr.com.cn/html/2022-07/15/content_55895_15051153.htm'
          },
          {
            title: '中国高铁再次开启“350”模式，《铁骨铮铮》诠释高铁匠人精神',
            date: '2022.07.15',
            href: 'https://share.gmw.cn/e/2022-07/15/content_35886619.htm'
          },
          {
            title: '中国作家网书单——2022年第二季度网络文学新作推介',
            date: '2022.07.14',
            href: 'http://www.chinawriter.com.cn/n1/2022/0714/c404023-32475790.html'
          },
          {
            title: '掌阅科技IP《仙帝归来》动态漫作品正在全网热播中',
            date: '2022.07.08',
            href: 'https://baijiahao.baidu.com/s?id=1737752489118586418'
          },
          {
            title: '掌阅精选助力机构开展“礼赞二十大，书香励初心”主题阅读活动',
            date: '2022.07.06',
            href: 'http://www.jjckb.cn/2022-07/06/c_1310638379.htm'
          },
          {
            title: '电子阅读器的下半场',
            date: '2022.07.03',
            href: 'https://web.app.workercn.cn/enews.html?content=353876&t=1656818590258'
          },
          {
            title: '“新时代 新经典”专栏重装上线掌阅平台，新增多部重点数字图书和有声书',
            date: '2022.06.30',
            href: 'https://tech.ifeng.com/c/8HFF14KFNUj'
          },
          {
            title: '掌阅科技布局元宇宙，推出阅读推广虚拟数字人“元壹梦”',
            date: '2022.06.30',
            href: 'https://baijiahao.baidu.com/s?id=1737034804427922923&wfr=spider&for=pc'
          },
          {
            title: '掌阅科技扛起“后Kindle时代”的数字阅读大旗',
            date: '2022.06.08',
            href: 'https://www.163.com/news/article/H9BA9V6900019UD6.html'
          },
          {
            title: '高考北京卷以在线为题，“在线”时代，你的阅读在哪里？',
            date: '2022.06.08',
            href: 'http://www.jjckb.cn/2022-06/08/c_1310617084.htm'
          },
          {
            title: 'Kindle，再见！数字阅读大时代，你好！',
            date: '2022.06.03',
            href: 'https://mp.weixin.qq.com/s/qsjXg8aUoFqmimFW158JUw'
          },
          {
            title: 'Kindle中国电子书店将停止运营 国产电子阅读器掌阅iReader欲借机扩大国内市场',
            date: '2022.06.02',
            href: 'http://www.zqrb.cn/tmt/tmthangye/2022-06-02/A1654162642769.html'
          },
          {
            title: '产业 " Kindle退出中国市场，国产电纸书能否接过市场大旗？',
            date: '2022.06.02',
            href: 'https://www.ccmapp.cn/ccmapp3.0/index.html#/shareDetail?action=opendetail%3Brichtext%3B32e8d15d-08f0-4661-b50b-ca7a9e44e747&terminalid=undefined&siteId=1'
          },
          {
            title: 'Kindle退市无需惊慌，国产阅读器看好掌阅iReader',
            date: '2022.06.02',
            href: 'https://www.yaokejinews.com/5094/'
          },
          {
            title: '亚马逊中国关停Kindle中国电子书店，电子书阅读器该选何家？',
            date: '2022.06.02',
            href: 'https://www.yaokejinews.com/5092/'
          },
          {
            title: '数字赋能传统出版 立体化展现《重生——湘江战役失散红军记忆》',
            date: '2022.05.30',
            href: 'https://reader.gmw.cn/2022-05/30/content_35775564.htm'
          },
          {
            title: '数字阅读与传统出版融合之作—《重生—湘江战役失散红军记忆》',
            date: '2022.05.26',
            href: 'http://it.cri.cn/20220526/a711d2a1-b257-5ab0-823d-2123b614dc34.html'
          },
          {
            title: '掌阅科技携旗下多部优质作品致敬网络文学读书日',
            date: '2022.05.19',
            href: 'http://china.qianlong.com/2022/0519/7209173.shtml'
          },
          {
            title: '掌阅科技“先电后纸”出版模式再出新作，优秀现实题材网络文学作品《粮战》隆重出版',
            date: '2022.05.11',
            href: 'https://baijiahao.baidu.com/s?id=1732496515995232261&wfr=spider&for=pc'
          },
          {
            title: '一部好的纪实文学：2021年度“中国好书”《重生—湘江战役失散红军记忆》',
            date: '2022.05.11',
            href: 'http://china.qianlong.com/2022/0511/7177380.shtml'
          },
          {
            title: '从“倡导”到“深入推进”，掌阅科技持续探索全民阅读推广新路径',
            date: '2022.05.06',
            href: 'https://baijiahao.baidu.com/s?id=1732042747045566019&wfr=spider&for=pc'
          },
          {
            title: '掌阅科技布局IP短剧领域初见成效，多部作品抖音播放量达数千万',
            date: '2022.04.30',
            href: 'https://finance.ifeng.com/c/8FbwQ3QuUhc'
          },
          {
            title: '一场春日阅读盛筵 推动全民阅读走实走深',
            date: '2022.04.29',
            href: 'https://reader.gmw.cn/2022-04/29/content_35700522.htm'
          },
          {
            title: '掌阅精选助力开封市图书馆推广全民阅读，启动“让阅读点亮城市”系列活动',
            date: '2022.04.27',
            href: 'https://baijiahao.baidu.com/s?id=1731224779060270536&wfr=spider&for=pc'
          },{
            title: '“第23届中国专利奖”揭晓，掌阅科技连续第二次荣膺“中国专利优秀奖”',
            date: '2022.04.26',
            href: 'https://cn.chinadaily.com.cn/a/202204/26/WS62676926a3101c3ee7ad27e8.html'
          },{
            title: '推进全民阅读，掌阅精选农家书屋在行动',
            date: '2022.04.25',
            href: 'https://cn.chinadaily.com.cn/a/202204/25/WS626638afa3101c3ee7ad2531.html'
          },{
            title: '2021年度“中国好书”揭晓，掌阅科技《重生——湘江战役失散红军记忆》入选',
            date: '2022.04.24',
            href: 'https://mp.weixin.qq.com/s/lbCi1IAirGblnvmgEM4NdQ'
          },{
            title: '掌阅精选独家策划 | 9个镜头下的瞬间，是诗和远方，是人间烟火',
            date: '2022.04.24',
            href: 'https://news.sina.com.cn/sx/2022-04-24/detail-imcwiwst3675634.shtml'
          },{
            title: '首届全民阅读大会开幕，掌阅科技携旗下多款电子书阅读器参展',
            date: '2022.04.23',
            href: 'https://mp.weixin.qq.com/s/6PU5qVBbDCt1rYTuVcUPQw'
          },{
            title: '季栋梁长篇报告文学《西海固笔记》研讨会在京举办',
            date: '2022.04.23',
            href: 'https://mp.weixin.qq.com/s/I4b7L5tOrRb5rTIPpARr0A'
          },{
            title: '掌阅世界读书日特辑丨每一个会写故事的人都有个有趣的灵魂',
            date: '2022.04.23',
            href: 'https://finance.ifeng.com/c/8FQKd0kOpvk'
          },{
            title: '掌阅科技2021年营收20.71亿元 免费阅读业务成主要增长来源',
            date: '2022.04.22',
            href: 'https://wap.egsea.com/detail/article?id=1150180'
          },{
            title: '读写更专注，掌阅举办2022年iReader春季新品发布会',
            date: '2022.04.22',
            href: 'https://baijiahao.baidu.com/s?id=1730772686312429517'
          },{
            title: '从天干地枯到美如仙境，《山海情》原型西海固地区的蜕变之路',
            date: '2022.04.21',
            href: 'http://culture.ynet.com/2022/04/21/3455631t467.html'
          },{
            title: '在世界读书日抓住那个会写故事的人，掌阅开启4·23系列活动',
            date: '2022.04.18',
            href: 'https://mp.weixin.qq.com/s/n-0Yl4dxHzRp6r--QJQouA'
          },{
            title: '掌阅科技旗下掌阅精选加入CARSI联盟，为高校师生提供多样化阅读服务',
            date: '2022.04.12',
            href: 'https://finance.sina.com.cn/jjxw/2022-04-13/doc-imcwiwst1484479.shtml'
          },{
            title: '掌阅精选开启世界读书日“阅读，不止这一天”主题系列活动',
            date: '2022.04.07',
            href: 'http://china.qianlong.com/2022/0407/7057740.shtml'
          },{
            title: '掌阅推出“国际儿童图书日”主题书单，鼓励青少年享受阅读乐趣',
            date: '2022.04.01',
            href: 'https://finance.ifeng.com/c/8Eq1pQA9q9N'
          },{
            title: '掌阅科技携手北京十月文艺出版社，“纸电同步”出品《西海固笔记》',
            date: '2022.03.30',
            href: 'https://mp.weixin.qq.com/s/_oJ0wmWJdsXQzp0jcWFzfA'
          },{
            title: '掌阅科技IP《武映三千道》同名改编动漫上线腾讯视频',
            date: '2022.03.28',
            href: 'https://mp.weixin.qq.com/s/uCn45muFP80qMtV49GZQuQ'
          },{
            title: '季栋梁写《西海固笔记》：全景式描绘这片土地的沧桑巨变',
            date: '2022.03.23',
            href: 'https://mp.weixin.qq.com/s/qwwjfEblspmAK84OFGrwZg'
          },{
            title: '聚焦粮食安全问题，掌阅科技原创现实题材网络文学作品《粮战》出版',
            date: '2022.03.21',
            href: 'https://cn.chinadaily.com.cn/a/202203/21/WS623842eea3101c3ee7acc9ff.html'
          },{
            title: '掌阅科技发送抗疫爱心大礼包，免费发放1000万份14天VIP会员卡，总价值达8400万',
            date: '2022.03.19',
            href: 'https://tech.china.com/article/20220319/032022_1033410.html'
          },{
            title: '掌阅科技持续探索“先电后纸”模式，助力数字与传统出版融合发展',
            date: '2022.03.18',
            href: 'https://mp.weixin.qq.com/s/y0GFmnl5wHRBWPZIlq_Klw'
          },{
            title: '全民阅读第9次被写入政府工作报告，掌阅科技积极践行全民阅读推广',
            date: '2022.03.10',
            href: 'http://news.hexun.com/2022-03-10/205467579.html'
          },{
            title: '掌阅科技推出掌阅精选机构服务，助力企业打造书香氛围',
            date: '2022.03.10',
            href:'https://cn.chinadaily.com.cn/a/202203/10/WS6229c6c6a3107be497a0a58a.html'
          },{
            title: '2022两会再成热点，掌阅科技强化创新引领，致力知识产权保护',
            date: '2022.03.09 ',
            href:'https://finance.ifeng.com/c/8EDSBP8PoDw'
          },{
            title: '读书再登两会热词榜，掌阅精选机构服务致力营造“书香政协”氛围',
            date: '2022.03.09',
            href:'https://www.163.com/news/article/H21B27T800019UD6.html'
          },{
            title: '阅读成为乡村文化振兴利器 打造数字平台助推农家书屋可持续发展',
            date: '2022.03.07 ',
            href:'https://reader.gmw.cn/2022-03/07/content_35569487.htm'
          },{
            title: '涨“书香气”更浓使“爱书者”更悦，掌阅精选机构服务“书香社会”建设 助力“全民阅读”事业纪实',
            date: '2022.03.04 ',
            href:'http://dzb.rmzxb.com.cn/rmzxbPaper/pc/con/202203/04/content_20465.html'
          },{
          title: '掌阅科技独家上线季栋梁《西海固笔记》，致敬新时代脱贫攻坚伟业',
          date: '2022.02.21',
          href:'https://mp.weixin.qq.com/s/edoeChABfjJThRFoQ-Bpew'
        },{
          title: '掌阅科技助力内蒙古数字草原书屋开展“书香内蒙古·书香伴你过大年”活动',
          date: '2022.02.07',
          href:'https://baijiahao.baidu.com/s?id=1724070249560035699&wfr=spider&for=pc'
        },{
          title: '加大线上文化内容供给，春节期间掌阅科技上线多个阅读活动',
          date: '2022.02.05',
          href:'http://news.hexun.com/2022-02-05/205241748.html'
        },{
          title: '“居家读好书，健康过春节”，掌阅精选提供30天数字图书馆免费使用服务',
          date: '2022.02.03',
          href:'https://finance.ifeng.com/c/8DIlwAk4b3n'
        },{
          title: '“2022网络大过年”活动启动，掌阅科技助力打造书香虎年',
          date: '2022.01.26',
          href:'https://finance.ifeng.com/c/8D5im23IRW6'
        },{
            title: '跟踪研究报告：领跑全球移动阅读市场，深化IP衍生产业链布局',
            date: '2022.01.15',
            href: 'http://news.10jqka.com.cn/field/sr/20220117/34049709.shtml'
        },{
            title: '一图看懂2021年度掌阅数字阅读报告',
            date: '2022.01.14',
            href:'https://mp.weixin.qq.com/s/cgndJ6LY12l0CIKR0eBAhg'
        },{
            title: '掌阅科技重磅发布《2021年度掌阅数字阅读报告》',
            date: '2022.01.14',
            href:'https://mp.weixin.qq.com/s/-3EOFRGr7JCB01ev8wjbdg'
        },{
            title: '张凌云委员：培育首都“文化+数字”新业态',
            date: '2022.01.08',
            href: 'https://ie.bjd.com.cn/5b165687a010550e5ddc0e6a/contentApp/5b16573ae4b02a9fe2d558f9/AP61d99373e4b0b9f572c9b7dc?isshare=1&app=3f3bf335eadde3b4&contentType=0&isBjh=0'
        }, {
            title: '2021年掌阅科技获奖作品、作家盘点',
            date: '2022.01.05',
            href: 'https://tech.ifeng.com/c/8CX1yvJTVuO'
        }, {
            title: '北京市政协委员张凌云：推动国家文化传播能力',
            date: '2022.01.04',
            href: 'https://baijiahao.baidu.com/s?id=1720990196203109335&wfr=spider&for=pc'
        }]
    }, {
        date: '2021',
        list: [{
                title: '深耕数字阅读十三年，掌阅科技斩获三项雇主大奖',
                date: '2021-12-30',
                href: 'https://finance.ifeng.com/c/8CORTr5NyZ5',
            }, {
                title: '掌阅科技都靓获颁北京阅读季“2021年度金牌阅读推广人”',
                date: '2021-12-29',
                href: 'https://finance.sina.com.cn/jjxw/2021-12-29/doc-ikyakumx7120926.shtml',
            }, {
                title: '“全民阅读推广的数字化创新与应用”入选2021年北京市全民阅读优秀项目',
                date: '2021-12-28',
                href: 'https://reader.gmw.cn/2021-12/28/content_35413017.htm',
            }, {
                title: '阅读“风帆好书榜”发布 主题出版物和现实题材原创文学表现亮眼',
                date: '2021-12-24',
                href: 'https://zj.zjol.com.cn/news.html?id=1783416',
            }, {
                title: '掌阅科技自有IP改编沉浸剧本杀《元龙》亮相第28届中国国际广告节',
                date: '2021-12-17',
                href: 'https://cn.chinadaily.com.cn/a/202112/17/WS61bc48d0a3107be4979fda7e.html',
            }, {
                title: '掌阅科技举办2021年度电子书阅读盛典',
                date: '2021-12-10',
                href: 'https://finance.ifeng.com/c/8BpvCKfkAWx',
            },
            {
                title: '数字阅读打造书香社会，掌阅科技助力第九届北京惠民文化消费季',
                date: '2021-12-03',
                href: 'http://finance.sina.com.cn/jjxw/2021-12-03/doc-ikyakumx1830906.shtml',
            },
            {
                title: '掌阅科技焕新出发，宣布正式启用全新LOGO',
                date: '2021-11-25',
                href: 'https://finance.ifeng.com/c/8BQuSoMWwhF',
            },
            {
                title: '掌阅科技斩获第九届梅花创新奖“最佳效果创新奖银奖”殊荣',
                date: '2021-11-24',
                href: 'https://www.163.com/news/article/GPIEAV8S00019UD6.html',
            },
            {
                title: '掌阅科技与中国图书馆学会达成合作，助力推广文津奖和“中国阅读”上榜图书',
                date: '2021-11-19',
                href: 'https://cn.chinadaily.com.cn/a/202111/19/WS61976e53a3107be4979f915f.html',
            },
            {
                title: '掌阅科技获评北京市“两新”组织“党建强、发展强”党建品牌项目',
                date: '2021-11-12',
                href: 'https://finance.eastmoney.com/a/202111122179412330.html',
            },
            {
                title: '政策面再迎利好 掌阅科技知识产权保护显优势',
                date: '2021-11-10',
                href: 'http://m.zqrb.cn/gscy/gongsi/2021-11-10/A1636549631364.html',
            },
            {
                title: '掌阅科技Q3营收5.02亿元 活跃用户规模实现逆势增长',
                date: '2021-11-02',
                href: 'http://news.hexun.com/2021-11-02/204657249.html',
            },
            {
                title: '财说 | 网文行业“近身肉搏”，掌阅科技用利润换市场？',
                date: '2021-11-01',
                href: 'https://m.jiemian.com/article/6765837.html',
            },
            {
                title: '掌阅科技打造“内蒙古数字草原书屋”，解决农牧民“买书难、借书难、看书难”',
                date: '2021-10-30',
                href: 'https://m.jiemian.com/article/6765837.html',
            },
            {
                title: '掌阅发布三季度财报 继续加大版权与短剧投入',
                date: '2021-10-29',
                href: 'https://m.yicai.com/news/101213248.html',
            },
            {
                title: '掌阅科技Q3营收5.02亿元 活跃用户规模实现逆势增',
                date: '2021-10-29',
                href: 'https://m.21jingji.com/article/20211029/herald/d3fe6649048e294cfdc78667edda6a43.html',
            },
            {
                title: '掌阅科技前三季度营收15.97亿元持续加大研发及版权购买投入',
                date: '2021-10-29',
                href: 'https://wap.egsea.com/detail/article?id=1002007',
            },
            {
                title: '掌阅科技前三季度净利润1.49亿元 加大研发投入强化内核实力',
                date: '2021-10-29',
                href: 'http://m.zqrb.cn/gscy/gongsi/2021-10-29/A1635507739135.html',
            },
            {
                title: '掌阅科技前三季营收15.97亿元 全面提升精细化运营能力',
                date: '2021-10-29',
                href: 'https://www.163.com/news/article/GNI5EIDF00019UD6.html',
            },
            {
                title: '掌阅科技iReader电子书阅读器发布多款新品，创新研发REINK智能排序引擎引关注',
                date: '2021-10-21',
                href: 'https://www.163.com/tech/article/GMRGAHIF00099BK0.html',
            },
            {
                title: '多部出版有声内容亮相掌阅听书节',
                date: '2021-10-15',
                href: 'https://reader.gmw.cn/2021-10/15/content_35235978.htm',
            },
            {
                title: '掌阅科技第四次获文化企业30强提名 彰显社会效益与经济效益统一',
                date: '2021-09-24',
                href: 'http://life.jrj.com.cn/2021/09/24162633510298.shtml',
            },
            {
                title: '第十七届深圳文博会开幕，掌阅科技携旗下多款电子书阅读器参展',
                date: '2021-09-23',
                href: 'https://reader.gmw.cn/xinxi/2021-09/23/content_35185573.htm',
            }

        ]
    },
    // {
    //     date: '2020',
    //     list: [{
    //             title: '掌阅科技Q3营收5.02亿元 活跃用户规模实现逆势增',
    //             date: '2020-10-29',
    //             href: 'https://m.21jingji.com/article/20211029/herald/d3fe6649048e294cfdc78667edda6a43.html',
    //         },
    //         {
    //             title: '掌阅科技前三季度营收15.97亿元持续加大研发及版权购买投入',
    //             date: '2020-10-29',
    //             href: 'https://wap.egsea.com/detail/article?id=1002007'
    //         },
    //         {
    //             title: '掌阅精选助力江苏省 “百馆荐书 全省共读”阅读推广活动全面启动',
    //             date: '2020-10-16',
    //             href: 'http://science.china.com.cn/2020-08/18/content_41260594.htm'
    //         },
    //         {
    //             title: '中国光大银行专属职工电子书屋正式上线了！',
    //             date: '2020-10-16',
    //             href: 'http://hn.ifeng.com/a/20200731/14425384_0.shtml'
    //         },
    //         {
    //             title: '第五届中国“网络文学+”大会开幕，掌阅科技“铁骨铮铮”号启程',
    //             date: '2020-10-10',
    //             href: 'https://baijiahao.baidu.com/s?id=1713216534416505869&wfr=spider&for=pc',
    //         },
    //         {
    //             title: '掌阅科技上半年实现净利1.1亿元 拓展to B服务“掌阅精选”',
    //             date: '2020-08-27',
    //             href: 'https://baijiahao.baidu.com/s?id=1676155155738804607&wfr=spider&for=pc',
    //         },
    //         {
    //             title: '掌阅精选助力江苏省 “百馆荐书 全省共读”阅读推广活动全面启动',
    //             date: '2020-07-22',
    //             href: '',
    //         },
    //         {
    //             title: '掌阅精选助力江苏省 “百馆荐书 全省共读”阅读推广活动全面启动',
    //             date: '2020-07-22',
    //             href: '',
    //         },
    //         {
    //             title: '掌阅精选助力江苏省 “百馆荐书 全省共读”阅读推广活动全面启动',
    //             date: '2020-07-22',
    //             href: '',
    //         }
    //     ]
    // }
]

export { allnewsList }