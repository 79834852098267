<template>
    <div id="privacy" class="privacy">
        <div class="inner_con">
            <h1>隐私策略</h1>
            <div class="subtit">隐私保护</div>
            <div class="subcon">
                1、用户同意，个人隐私信息是指那些能够对用户进行个人辨识或涉及个人通信的信息，包括下列信息：用户真实姓名，身份证号，手机号码，IP地址。而非个人隐私信息是指用户对本服务的操作状态以及使用习惯等一些明确且客观反映在本公司服务器端的基本记录信息和其他一切个人隐私信息范围外的普通信息，以及用户同意公开的上述隐私信息；
            </div>
            <div class="subcon">2、保护用户(特别是未成年人)的隐私是掌阅的一项基本政策，掌阅将对用户所提供的资料进行严格的管理及保护，并使用相应的技术，防止用户的个人资料丢失、被盗用或遭篡改，保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在掌阅的非公开内容，但下列情况除外：
                <p>2.1 事先获得用户的明确授权；</p>
                <p>2.2 根据有关的法律法规要求；</p>
                <p>2.3 按照相关政府主管部门的要求；</p>
                <p>2.4 为维护社会公众的利益；</p>
                <p>2.5 为维护掌阅的合法权益。</p>
            </div>
            <div class="subcon">
                3、任何时候如果您对我们的隐私策略有疑问，请利用电子邮件privacy@zhangyue.com 联系我们，我们会尽一切努力，请合理适当的范围内立即改善这个问题。
            </div><br>
            <div class="subtit">知识产权</div>
            <div class="subcon">1、掌阅系iReader的著作权人，未经掌阅许可，用户不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）。</div>
            <div class="subcon">2、掌阅提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在用户终端内。掌阅不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。</div>
            <div class="subcon">3、掌阅所有作品内容仅代表作者自己的立场和观点，与掌阅无关，由作者本人承担一切法律责任。</div>
        </div>    
    </div>
</template>
<script>
export default {
    name:'thems'
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.privacy{margin-top:82px}
.subcon{
    line-height: 1.9;
    margin-top:24px;
    font-size: 16px;
    color:#666;
    p{
        margin-top:16px;
    }
}
.inner_con{
    width: 780px;
    padding: 88px 0;
    margin:0 auto;
    h1{
        line-height: 48px;
        text-align: center;
        font-size: 28px;
        color: #333;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E6E6E6;
        font-weight: normal;
    }
}
.S{
    .privacy{margin-top:rem(148);}
    .inner_con{
        width:auto;
        padding:rem(92) rem(60) rem(124);
        h1{
            font-size:rem(60);
            color:#333;
            font-weight: normal;
            padding-bottom: rem(56);
            margin-bottom:rem(72)
        }
        .first_con{
            font-size:rem(42);
        }
        .sub_title{
            font-size:rem(48)
        }
        .subcon{
            font-size:rem(42)
        }
        li{
            padding-top:rem(80)
        }
    }
}
</style>