<template>
    <div id="allhonor">
        <div class="banner_honor"><img src="https://static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/banner_honor.54c0e30c.png" alt=""></div>
        <Breadbox />
        <div class="honor_container">
            <div class="honor_list">
                <div class="honoritem" v-for="item in honorList" :key="item">
                    <div class="honor_img"><img :src="item.src" alt="" /></div>
                    <div class="honor_title">{{item.honorName}}</div>
                    <div class="honor_info">{{item.honorInfo}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { honorList } from "@/config/about";
import Breadbox from "@/components/Breadbox";
import { Mixins } from '@ireader/core-lib';
export default {
    name:'Allhonor',
    mixins: [Mixins.sdd()],
    data:function(){
        return{
            honorList: []
        }
    },
    components: {
        Breadbox,
    },
    async mounted() {
        try {
            const res = await this.$getSdd('zycom/honor_list')
            this.honorList = res || honorList;
        } catch (e) {
            this.honorList = honorList;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/mixin.scss";
.banner_honor{
    text-align: center;
}
.honor_container{
    width: 992px;
    margin: 0 auto;
    padding:68px 0;
    .honor_img{
        width: 320px;
        height: 240px;
        overflow: hidden;
        img{
            display: block;
            width: 320px;
            height: 240px;
        }
    }
    .honor_title{
        padding: 32px 16px 0;
        font-size:16px;
        color:#333;
    }
    .honor_info{
        padding: 16px 16px 0;
        font-size:14px;
        color:#666;
    }
    .honor_list{
        margin-left:-16px;
        overflow: hidden;
        .honoritem{
            width: 320px;
            height: 424px;
            float: left;
            margin:20px 0 20px 16px;
            list-style: none;
        }
    }
}
</style>