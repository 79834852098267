<template>
  <div class="zy_bar" :class="{ zy_bar_fixed: scrollTop >= 1 || type === BAR_MENU_STATUS.default }">
    <div class="zy_constr">
      <div class="zy_hd_bar">
        <a class="zy_bar_menu" :class="{ active: showMenu }" @click="toggleMenu" />
        <a class="zy_bar_icon" href="/"></a>
        <div class="menu">
          <div class="zy_nav_join" @click="isShow=!isShow">
            加入掌阅
            <span v-if="isShow" class="icon up"></span>
            <span v-else class="icon down"></span>
          </div>
        <div class="down_menu" v-show="isShow">
          <a target="_blank" href="https://q7w8vltyes.jobs.feishu.cn/zhangyue" class="btn">社会招聘</a>
          <a target="_blank" href="https://q7w8vltyes.jobs.feishu.cn/2024" class="btn">校园招聘</a>
        </div>
        </div>
      </div>
      <div id="zyHbBar" class="zy_hd_bar fixed" v-if="showMenu">
        <a class="zy_logo" href="/"></a>
        <div class="zy_nav">
          <a
            class="zy_nav_a"
            v-for="anchor in anchorList"
            :key="anchor.id"
            @click="toAnchor(anchor)"
            :class="{ active: activeAnchor === anchor.id}"
          >{{ anchor.name }}</a>
        </div>
        <div class="menu">
          <div class="zy_nav_join pc">加入掌阅</div>
          <div class="down_menu">
            <div class="content">
              <a target="_blank" href="https://q7w8vltyes.jobs.feishu.cn/zhangyue" class="btn">社会招聘</a>
              <a target="_blank" href="https://q7w8vltyes.jobs.feishu.cn/2024" class="btn">校园招聘</a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="mask" @click="clickMask" v-if="showMenu && isMobile"></div>
  </div>
</template>

<script>
import { nav, BAR_MENU_STATUS } from "@/config";
import { scrollTo } from "@/utils/scroll-to";
import {isMobile} from '@/utils/utils'
import {throttle} from '@/utils/utils';

export default {
  name: "Bar",
  props: {
    type: {
      default: BAR_MENU_STATUS.default
    }
  },
  data: function() {
    return {
      isShow: false,
      BAR_MENU_STATUS,
      anchorList: nav, // 导航列表
      offSet: 0, // 锚点位置相对于原位置的偏移量
      fixedHeader: true, // 是否存在固定页头
      headerHeight: 80, // 页头高度
      stepHeight: 60, //分布定位每次渲染移动多少像素
      activeAnchor: this.$route.query.id, // 当前定位的锚点
      anchorPosition: [], //存放各锚点位置
      scrollTop: 0,
      //  如果是手机端，初始化是false；如果是PC端，一直保持true
      showMenu: !isMobile(),
      contactArr:['Terms','Copyright','Privacy'],
      // 是否为手机
      isMobile: isMobile()
    };
  },
  mounted: function() {
    this.initPosition();
    // 强制重写 activeAnchor
    this.activeAnchor = this.$route.query.id
  },
  destroyed: function() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    initPosition() {
      if (this.anchorList.length === 0) {
        return;
      }
      // 如果为动态锚点，自动定位当前所属锚点
      window.addEventListener("scroll", throttle(this.onScroll, 150, {trailing: true}), true);
      this.onScroll();
      this.activeAnchor = null;
      this.anchorPosition = [];
    },
    onScroll() {
      if (this.anchorList.length === 0 || this.$route.name !== 'Home') {
        return;
      }
      if (this.anchorPosition.length === 0) {
        this.anchorList.forEach(anchor => {
          const toElement = document.querySelector("#" + anchor.id);
          if (toElement) {
            this.anchorPosition.push({
              id: anchor.id,
              position:
                toElement.offsetTop +
                (this.fixedHeader ? -this.headerHeight : this.headerHeight)
            });
          }
        });
      }
      // 滚动条上端距离顶部的距离
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrolled;
      scrolled = Math.ceil(scrolled);
      // 将锚点位置列表根据锚点位置排序
      this.anchorPosition.sort(function(pre, next) {
        return pre.position - next.position;
      });

      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight; // 可视区的高度
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      // 页面滑动到底部时直接定位到最后的footer
      if (scrolled + windowHeight >= scrollHeight) {
        this.activeAnchor = this.anchorPosition[this.anchorPosition.length - 1].id;
        return
      }

      // 根据页面位置进行当前锚点高亮
      for (let i = 0; i < this.anchorPosition.length - 1; i++) {
        if (
          scrolled >= this.anchorPosition[i].position &&
          scrolled < this.anchorPosition[i + 1].position
        ) {
          this.activeAnchor = this.anchorPosition[i].id;
        }
      }
      if (this.anchorPosition.length === 0) {
        return;
      }
      if (
        scrolled >= this.anchorPosition[this.anchorPosition.length - 1].position
      ) {

          this.activeAnchor = this.anchorPosition[
              this.anchorPosition.length - 1
            ].id;
      }
    },
    toAnchor(anchor) {

      if (anchor.clickEvent && !anchor.clickEvent()) {
        return;
      }
      this.$router.push({ path: '/home', query: { id: anchor.id }});

      this.activeAnchor = anchor.id;
      const toElement = document.querySelector("#" + anchor.id);
      if (toElement) {
        const headerHeight = this.fixedHeader
          ? -this.headerHeight
          : this.headerHeight;
        const scrollTop = toElement.offsetTop + this.offSet + headerHeight;
        if (scrollTop === document.documentElement.scrollTop) {
          return;
        }
        // this.changeMenu();
        scrollTo(scrollTop, 800);
      }
    },
    toggleMenu() {
        //如果是手机端，初始化是false；如果是PC端，一直保持true
        if (isMobile()) {
            this.showMenu = !this.showMenu;
        }
    },
    hideMenu() {
        this.showMenu = false
    },
    // 点击弹层
    clickMask(e){
      e.stopPropagation()
      this.hideMenu()
    }
  },
  watch:{
    //从二级页面回来时候定位点击的当前导航对应模块儿位置
    $route(to){
      //首页滑到底部点击三个隐私政策三个导航跳转二级页面导航联系我们高亮
      if(this.contactArr.indexOf(this.$route.name)>-1){
        this.activeAnchor = 'contact'
        this.anchor.id = 'contact'
      }
      //除了首页二级页面回来定位
      if(this.$route.name != 'Home'){
        return
      }
      //从二级页面回来定位点击导航对应的位置
      const toElement = document.querySelector("#" + to.query.id);
      if (toElement) {
        const headerHeight = this.fixedHeader
          ? -this.headerHeight
          : this.headerHeight;
        const scrollTop = toElement.offsetTop + this.offSet + headerHeight;
        if (scrollTop === document.documentElement.scrollTop) {
          return;
        }
        isMobile() && this.hideMenu();
        scrollTo(scrollTop, 800);
      }
    }
  },
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zy_bar_fixed {
  height: 79px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  box-shadow: none;
}

.S .zy_bar_fixed {
  height: rem(148);
}

.S .fixed.zy_hd_bar,
.zy_bar_fixed {
  //display: block;
  background-color: $mainText;
  z-index: 11;
}

.zy_hd_bar {
  position: relative;
  color: $mainText;
  z-index: 3;
  transition: all 1s;
}

.menu:hover .down_menu {
  display: block;
}

.down_menu {
  padding-top: 4px;
  display: none;
  z-index: 999;
  position: absolute;
  top: 80px;
  box-sizing: border-box;
  right: 0;
  .content {
    background: rgba(248, 248, 248, 0.95);
    padding: 17px 0px;
    .btn {
      display: block;
      width: 120px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      color: #222;
      &:hover{
        color: #E8554D;
      }
    }
  }
}

.S .zy_hd_bar,
.zy_hd_bar[id] {
  display: block;
}

.zy_hd_bar {
  display: none;
}

.S .zy_hd_bar {
  &:nth-child(2) {
    .zy_nav_join {
      display: none;
    }
  }

  .zy_logo {
    display: none;
  }
  .zy_nav {
    top: rem(148);
    left: 0px;
    right: 0px;
    background-color: #f7f7fa;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.25s, opacity 0.25s;
    transition: visibility 0.25s, opacity 0.25s;

    .zy_nav_a {
      display: block;
      text-align: center;
      padding: rem(0) 0;
      margin-right: 0px;
      box-shadow: none;
      transition: padding 0.25s, line-height 0.25s;
      &.active {
        box-shadow: none;
      }
    }
  }
}

.S .fixed.zy_hd_bar {
  .zy_nav {
    visibility: visible;
    opacity: 1;
    .zy_nav_a {
      padding: rem(70) 0;
    }
  }
}

.S .fixed.zy_hd_bar,
.zy_bar_fixed {
  //display: block;
  background-color: #fff;
  color: #202425;
  z-index: 11;
}

.zy_logo {
  position: absolute;
  top: 19px;
  width: 128px;
  height: 42px;
  background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/logo.a50897bc.png") no-repeat;
  background-size: 100% 100%;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  z-index: 1;
}

//.fixed .zy_logo,
.zy_bar_fixed .zy_logo {
  background-size: 100% 100%;
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/logo_active.29255e19.png"), none;
}

.zy_nav {
  position: absolute;
  top: 0;
  left: 186px;
  letter-spacing: -0.25em;
  z-index: 1;
  font-size: 0;
}

.zy_nav_a {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding: 30px 0px;
  letter-spacing: 0;
  font-size: 14px;
  transition: all 0.25s;
  margin-right: 32px;
  cursor: pointer;
  &:hover{
    box-shadow: 0 1px #fff, 0 2px #fff, 0 4px #fff;
  }
}

.zy_bar_fixed .zy_nav .zy_nav_a {
  color: $mainDarkText;
  &:hover {
    color: $mainActive;
    box-shadow: none;
  }
}

.zy_bar_fixed .zy_nav > .active,
.zy_bar_fixed .zy_nav .router-link-active {
  color: $mainActive;
  padding: 30px 0px 26px;
  //border-bottom: 4px solid $mainActive;
  border-bottom: 0 transparent;
  box-shadow: 0 1px #FC5649, 0 2px #FC5649, 0 4px #FC5649;

}

.zy_nav_join {
  /* height: 28px;
  line-height: 29px;
  padding: 0px 16px;
  border-radius: 2px;
  border: 1px solid $mainText; */
  position: absolute;
  top: 0;
  right: 32px;
  z-index: 1;
  height: 20px;
  line-height: 20px;
  padding: 30px 0px;
  letter-spacing: 0;
  font-size: 14px;
  transition: all 0.25s;
  cursor: pointer;
}

.menu:hover .zy_nav_join {
  box-shadow: 0 1px #fff, 0 2px #fff, 0 4px #fff;
}

.S {
  .menu:hover .zy_nav_join {
    box-shadow: none;
  }
}

.zy_bar_fixed {
  .menu:hover .zy_nav_join {
    box-shadow: none;
    color: $mainActive;
  }
  .menu {
    .down_menu {
      padding-top: 0px;
      top: 79px
    }
  }
}

.S .zy_nav_join {
  padding: 0px;
  top: rem(48);
  right: rem(18);
  font-size: rem(36);
  height: rem(68);
  line-height: rem(70);
  .icon {
    display: inline-block;
    width: rem(28*3);
    height: rem(15*3);
    transform: scale(0.4);
    vertical-align: middle;
    margin-left: rem(-15);
    &.up {
      background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/join_up.png") no-repeat;
    }
    &.down {
      background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/join_down.png") no-repeat;
    }
  }
}

.S .zy_bar_fixed .zy_nav_join {
  &:hover {
    color: #333;
  }
}

.S .menu {
  position: relative;
  top: -32px;
}

.S .down_menu {
  position: absolute;
  box-sizing: border-box;
  padding: rem(56) 0;
  // top: rem(142);
  top: rem(148);
  right: rem(18);
  width: rem(308);
  background: rgba(248, 248, 248, 0.95);
  .btn {
    display: block;
    height: rem(107);
    text-align: center;
    line-height: rem(107);
    color: #222;
    font-size: rem(36);
  }
}

.S .zy_bar_fixed {
  .zy_nav_join {
    .icon {
      &.up {
        background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/join_up_black.png") no-repeat;
      }
      &.down {
        background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/join_down_black.png") no-repeat;
      }
    }
  }
  .menu .down_menu {
    padding-top: rem(56);
    top: rem(148);
  }
}

.zy_bar_fixed .zy_nav_join {
  color: $mainDarkText;
  border-color: #999;
  /* &:hover {
    color: #ffffff;
    border: none;
    background-image: linear-gradient(to bottom right, #ff7252, #ff4d40);
  } */
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .zy_logo {
    background-size: 100% 100%;
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/logo.a50897bc.png"), none;
  }
}

.S .zy_hd_bar {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
}

.S .zy_bar_menu {
  width: rem(48);
  height: rem(42);
  display: inline-block;
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/menu.png");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: rem(53);
  margin-left: rem(60);
  vertical-align: middle;
}

.S .zy_bar_fixed .zy_bar_menu {
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/menu_active.png");
  &.active {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/menu_close.png");
  }
}

.zy_bar_icon {
  width: rem(240);
  height: rem(64);
  display: inline-block;
  background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/logo_white.d11929bd.png") no-repeat;
  background-size: 100%;
  position: absolute;
  top: rem(45);
  left: rem(345);
  transform: translateX(30%);
}

.S .zy_bar_fixed .zy_bar_icon {
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/logo_phone_active.bd9770cb.png");
}
.S{
  .zy_bar_icon{
    left:50%;
    transform: translateX(-50%);
    background: url('//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/logo_white_phone.c93dd4e4.png') no-repeat center;
    -webkit-background-size:rem(240) rem(59);
    background-size:rem(240) rem(59)
  }
}
.mask{
  width: 100%;
  height: 100%;
  position: fixed;
  top: rem(148);
  left: 0;
  z-index: 10;
}
</style>
