<template>
  <div id="about" class="zy_about">
    <div class="zy_constr">
      <h1 class="zy_about_title">{{about.title}}</h1>
      <p id="zy_about_desc" class="zy_about_desc" v-html="about.desc" />
      <p class="zy_about_desc" v-html="about.desc_app" />
      <div class="zy_about_info">
        <router-link
          class="zy_arrow zy_about_arrow"
          v-for="info in about.infoList"
          :key="info.name"
          :to="info.href"
        >{{info.name}}</router-link>
      </div>
      <div id="zy_about_view" class="zy_about_view">
        <el-tabs v-model="activeName" @tab-click="clickTab">
          <el-tab-pane
            v-for="advantage in advantageList"
            :key="advantage.name"
            :name="advantage.name"
          >
            <template #label>
              <div class="zy_about_pane">
                <span class="zy_about_img" :class="advantage.icon" />
                <div class="zy_about_name">{{ advantage.name }}</div>
              </div>
            </template>
            {{ advantage.desc }}
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="zy_about_view">
        <h1>业务优势</h1>
        <swiper class="swiper" ref="mySwiper" :options="swiperOption" @slideChange="slideChange">
          <swiper-slide v-for="advantage in advantageList" :key="advantage.name">
            <div class="zy_constr">
              <span class="zy_about_img" :class="advantage.icon" />
              <h4>{{ advantage.name}}</h4>
              <p v-html="advantage.desc" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!-- todo 跳转链接 -->
        <router-link class="zy_biz_a" :name="'ad'+activeIdx" :to="{path:'/advantage', query: { id: activeIdx}}">详细了解</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { setting } from "@/config";
export default {
  name: "About",
  data: function() {
    const { about } = setting;
    const { advantageList } = about;
    return {
      about,
      advantageList,
      activeName: advantageList && advantageList[0] && advantageList[0].name,
      meritList: advantageList,
      index: 0,
      timer: null,
      activeIdx: 0,
      swiperOption: {
        loop: true,
        speed: 1500,
        slidesPerView: 3,
        autoplay: { delay: 5000 },
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        centeredSlides: true,
        watchSlidesProgress: true,
        on: {
          setTranslate: function() {
            const slides = this.slides;
            for (let i = 0; i < slides.length; i += 1) {
              const slide = slides.eq(i);
              // const progress = slides[i].progress;
              slide.css({ opacity: "", background: "" });
              slide.transform(""); //清除样式
              // if (progress !== 0) {
              //   slide.css("opacity", 0.1);
              // } else {
              //   slide.css("opacity", 1 - Math.abs(progress) / 6);
              // }
            }
          },
          setTransition: function(transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              slide.transition(transition);
            }
          }
        }
      }
    };
  },
  mounted() {
    this.timer = setInterval(this.setTimer, 5000);
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    setTimer() {
      this.index = this.index === 4 ? 0 : this.index + 1;
      this.activeName = this.meritList[this.index || 0].name;
    },
    clickTab(tab) {
      clearInterval(this.timer);
      this.activeName = tab.name;
      this.index = Number(tab.index);
      this.timer = setInterval(this.setTimer, 5000);
    },
    slideChange(){
      this.activeIdx = this.$refs.mySwiper.swiper.realIndex
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zy_about {
  min-height: 781px;
}

.zy_about_title {
  font-size: 32px;
  color: #333;
  line-height: 45px;
  text-align: center;
  padding: 70px 0 30px 0;
}

.S .zy_about_title {
  padding: rem(164) 0 rem(56) 0;
  font-size: rem(60);
}

.zy_about_desc {
  font-size: 16px;
  color: #666;
  padding: 0px 95px;
  line-height: 28px;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    top: -8px;
    content: "";
    display: block;
    width: 48px;
    height: 48px;
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/comma.png");
    background-size: 100% 100%;
  }

  &:before {
    left: 0;
    transform: rotate(180deg);
  }

  &:after {
    right: 0px;
  }
}

.S .zy_about_desc,
.zy_about_desc[id] {
  display: block;
}

.S .zy_about_desc[id],
.zy_about_desc {
  display: none;
}

.S .zy_about_desc {
  font-size: rem(42);
  padding: 0 rem(60);
  &:before,
  &:after {
    display: none;
  }
}

.zy_about_info {
  text-align: center;
  margin-top: 27px;
  margin-bottom: 62px;

  .zy_arrow {
    &:last-child {
      margin-right: 0px;
    }
  }
}

.S .zy_about_info {
  margin-bottom: rem(124);
}

.zy_about_arrow {
  margin-right: 58px;
  position: relative;
  color: #FC5649!important;
  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    position: absolute;
    background-color: #f5f5f5;
    top: 5px;
    left: -32px;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }

  &::after {
    background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_active.png")!important;
  }

  &:hover {
    color: $mainHover!important;
    &::after {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_hover.png")!important;
    }
  }

  &:active {
    color: $mainActived!important;
     &::after {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_visited.png")!important;
    }
  } 
}

.S .zy_about_arrow {
  font-size: rem(36);
  margin-right: rem(131);
  &:last-child {
    margin-right: 0;
  }
  &::before {
    top: rem(5);
    left: rem(-64);
  }
  &::after {
    vertical-align: bottom;
    background-position: center rem(3);
  }
}

.S .zy_about_view,
.zy_about_view[id] {
  display: block;
}

.S .zy_about_view[id],
.zy_about_view {
  display: none;
}

.zy_about_view {
  .el-tabs__nav {
    padding-bottom: 20px;
  }

  .el-tabs__nav-wrap::after {
    background-color: #f5f5f5;
    height: 1px;
  }

  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 74px;
  }

  .zy_about_pane {
    width: 100px;
  }

  .zy_about_name {
    height: 22px;
    line-height: 22px;
    text-align: center;
    font-size: 16px;
    color: #666;
    font-weight: normal;
    margin-top: 16px;
    &:hover {
      color: $mainActive;
    }
  }

  .el-tabs__item {
    padding: 0px;
    padding-right: 91px;
    box-sizing: border-box;
    &.is-active{
      .zy_about_name{
        color:#FC5649;
      }
    }
  }
  .zy_about_img {
    width: 90px;
    height: 90px;
    display: block;
    background-size: 100%;
    margin: 0px auto;

    &.about_book {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/about_book.2db65d2e.png");
    }
    &.about_skill {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/about_skill.50b77202.png");
    }
    &.about_copyright {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/about_copyright.a6e88717.png");
    }
    &.about_operate {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/about_operate.d01cbfe5.png");
    }
    &.about_terminal {
      background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/about_terminal.0dbd4578.png");
    }
  }

  .el-tabs__content {
    padding-top: 27px;
    .el-tab-pane {
      font-size: 14px;
      color: #666;
      line-height: 25px;
    }
  }
  .el-tabs__active-bar {
    background-color: $mainActive;
  }
}

.S .zy_about_view {
  height: rem(1329);
  background: #f7f7fa;
  position: relative;

  h1 {
    font-size: rem(60);
    text-align: center;
    padding: rem(164) 0 rem(124) 0;
  }

  .swiper {
    height: 100%;
  }

  .zy_about_img {
    width: rem(240);
    height: rem(240);
  }
  .zy_biz_a {
    width: rem(304);
    height: rem(82);
    line-height: rem(81);
    border-radius: rem(6);
    border: 1px solid #999;
    color: #999;
    font-size: rem(36);
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    bottom: rem(124);
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }

  .swiper-slide {
    h4 {
      font-size: rem(48);
      color: $mainActive;
      text-align: center;
      margin: rem(64) 0 rem(32) 0;
      font-weight: normal;
      display: none;
    }
    p {
      font-size: rem(42);
      line-height: rem(72);
      position: absolute;
      left: rem(-260);
      width: calc(300% - rem(260));
      height: rem(144);
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-indent: -2em;
      padding-left: 2em;
      display: none;
    }
    .zy_about_img {
      transform: scale(0.7);
      transition: all 0.6s;
      opacity: .1;
    }
    .zy_biz_a {
      transition: all 1s;
      display: none;
      width: rem(304);
      height: rem(82);
      line-height: rem(81);
      margin: rem(380) auto 0;
      border-radius: rem(6);
      border: 1px solid #999;
      color: #999;
      font-size: rem(36);
      box-sizing: border-box;
      text-align: center;
    }
  }

  .swiper-slide-active {
    h4 {
      display: block;
    }
    p {
      display: -webkit-box;
    }
    .zy_biz_a {
      display: block;
    }
    .zy_about_img {
      transform: scale(1);
      opacity: 1;
    }
  }

  .swiper .swiper-pagination {
    top: rem(595);

    .swiper-pagination-bullet {
      width: rem(24);
      height: rem(24);
      border: rem(3) solid #ccc;
      background-color: #f7f7fa;
      border-radius: rem(24);
      box-sizing: border-box;
      opacity: 1;
      margin: 0 rem(20);
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #999;
      border: none;
    }
  }
}
</style>
