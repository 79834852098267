<template>
  <div id="contact" class="zy_footer">
    <div class="zy_contact">
      <div class="zy_constr">
        <div class="zy_con_icon" />
        <div class="zy_con_detail">
          <a v-for="(partner,index) in partnerList" :key="partner.module" @click="IsActive(index)" :class="activeClass==index?'hover':'show-more '"><!-- class="show-more"-->
            <h4>{{partner.module}}</h4>
            <template v-if="partner.list">
              <div v-for="(par, count) in partner.list" :key="count" class="zy_con_detail_info" :style="{marginTop: count !== 0 ? '5px': '0'}">
                <div>{{par.name}}</div>
                <div v-if="par.phone">电话：{{par.phone}}</div>
                <div v-if="par.wechat">微信：{{par.wechat}}</div>
                <div v-if="par.email">Email：{{par.email}}</div>
              </div>
            </template>

            <div v-if="!partner.list" class="zy_con_detail_info">
              <div>{{partner.name}}</div>
              <div v-if="partner.phone">电话：{{partner.phone}}</div>
              <div v-if="partner.wechat">微信：{{partner.wechat}}</div>
              <div v-if="partner.email">Email：{{partner.email}}</div>
            </div>
          </a>
        </div>
        <div class="zy_con_info">
          <div
            class="zy_con_info_item"
            v-for="contact in contactList"
            :key="contact.type"
            :class="contact.type"
          >
            <span class="zy_info_name">{{contact.name}}：</span>
            <span class="zy_info_desc">{{contact.info}}</span>
            <span></span>
          </div>
          <div class="zy_footer_share">
            <a
              class="share_icon"
              v-for="share in shareList"
              :key="share.type"
              :href="share.href"
              target="_blank"
              :class="'share_icon_'+ share.type"
            >{{share.name}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="zy_policy">
      <div class="zy_constr">
        <router-link class="footer_nav" v-for="policy in policyList" :key="policy.id" :to="policy.id">{{policy.name}}</router-link>
        <div>
          <a
            v-for="icp in icpList"
            target="_blank"
            :key="icp.id"
            :href="icp.href || ''"
          >{{icp.name}}</a>
        </div>
        <div>{{footer.copyright}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { setting } from "@/config";
import { Mixins } from '@ireader/core-lib';
export default {
  name: "Footer",
  mixins: [Mixins.sdd()],
  data: function() {
    const { footer } = setting;
    const { contact, share, policy, icp } = footer;
    return {
      footer,
      // partnerList: partners, // 商务合作
      partnerList: [],
      contactList: contact, // 联系方式
      shareList: share,
      policyList: policy,
      icpList: icp,
      activeClass:-1
    };
  },
  mounted() {
    this.getNewsList();
  },
  methods: {
    async getNewsList () {
      const { footer: { partners } } = setting;
      try { 
        const res = await this.$getSdd('zycom/partners')
        this.partnerList = res || partners;
      } catch (e) {
        this.partnerList = partners;
      }
    },
    IsActive(index) {
      this.activeClass = index;
      //console.log(11111111)
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zy_footer {
  .zy_contact {
    background-color: #19204b;
    padding-top: 70px;

    .zy_con_icon {
      width: 152px;
      height: 45px;
      background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/footer_logo.b1501af3.png") no-repeat;
      background-size: cover;
    }

    .zy_con_detail {
      padding: 24px 0px 30px;
      display: flex;
      flex-wrap: wrap;
      color: $mainText;
      line-height: 32px;
      > a {
        display: block;
        width: 33%;
        white-space: nowrap;
        &:hover {
          .zy_con_detail_info {
            height: 60px;
          }
        }
        &:nth-child(3n + 2) {
          width: 33%;
        }
        &:nth-child(3n) {
          width: 34%;
        }
      }

      h4 {
        font-weight: normal;
        font-size: 14px;
        display: inline-block;
        &:hover {
          color: $mainActive;
        }
      }

      .zy_con_detail_info {
        height: 0px;
        line-height: 20px;
        font-size: 12px;
        overflow: hidden;
        transition: all 0.4s;
      }

      &::before,
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $mainText;
        opacity: 0.3;
      }

      &::before {
        margin-bottom: 28px;
      }
      &::after {
        margin-top: 12px;
      }
    }

    .zy_con_info {
      color: #f7f7f7;
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 70px;
      position: relative;

      .zy_info_name {
        min-width: 70px;
        display: inline-block;
      }

      .zy_con_info_item {
        &::before {
          content: "";
          width: 26px;
          height: 26px;
          display: inline-block;
          background-size: cover;
        }
      }
      .service {
        &::before {
          background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/service.png");
          vertical-align: text-bottom;
        }
      }
      .address {
        &::before {
          background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/address.png");
          vertical-align: bottom;
        }
      }
      .phone {
        &::before {
          background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone.png");
          vertical-align: top;
        }
      }
      .fax {
        &::before {
          background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/fax.png");
          vertical-align: text-top;
        }
      }
    }

    .zy_footer_share {
      position: absolute;
      top: 84px;
      right: 0;
      white-space: nowrap;
      word-spacing: 15px;
    }
  }

  .zy_policy {
    // height: 58px;
    padding: 16px;
    background: #101519;
    font-size: 12px;
    color: #8c8c8c;
    line-height: 20px;
    text-align: center;
    a {
      // white-space: nowrap;
      margin-right: 16px;
    }
  }
}

.share_icon {
  display: inline-block;
  width: 41px;
  height: 41px;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 0;
  line-height: 0;
  color: transparent;
  vertical-align: middle;
  margin-right: 16px;
  &:last-child {
    margin-right: 0px;
  }
}
.share_icon_weixin {
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/wechat.f8da06b4.png");
  position: relative;
  &:hover {
    &::after {
      content: "";
      display: inline-block;
      width: 176px;
      height: 176px;
      background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/zy_qrcode.b742287c.png") no-repeat;
      background-size: cover;
      position: absolute;
      bottom: 65px;
      right: -57px;
    }
  }
}
.share_icon_weibo {
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/wb.10b73672.png");
}



.S{
  .zy_footer{
    .zy_contact{
      padding:rem(164) rem(60);
      .zy_con_icon{
        width: rem(407);
        height: rem(103);
        background-size: rem(407) rem(103);
      }
      .zy_con_detail{
        position: relative;
        padding: rem(80) 0 rem(80);
        margin:rem(60) 0;
        border-width: rem(3) 0 rem(3) 0;
        border-color:#fff;
        border-style:solid;
        overflow-x: scroll;
        > a {
          width: 100%;
          display: inline-block;
          &.hover{
            .zy_con_detail_info{
              height: 60px;
              font-size:rem(36);
              color:rgba(255,255,255,.7)
            }
          }
          h4{
            font-size:rem(42);
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
        &:before,&:after{
          display: none;
        }
      } 
      .zy_info_desc{
        display: inline-block;
        white-space: normal;
        font-size: rem(42);
        color:rgba(247,247,247,.7)
      }
      .zy_con_info{
        .zy_con_info_item{
          display: flex;
          display: -webkit-flex;
          &.address{
            .zy_info_name{
              padding-left: rem(20);
            }
          }
        }
      }
      .zy_footer_share{
        left: 0;
        top:auto;
        bottom:0;
      }   
    }
    .zy_policy{
      padding:rem(116) rem(60) rem(124);
      height: auto;
      .footer_nav{
        display: inline-block;
        padding:0 rem(48) rem(72);
        color:rgba(255,255,255,.7);
        &:nth-child(2){
          position: relative;
          &:before{
            content: '';
            position: absolute;
            left:0;
            top:rem(12);
            width: rem(2);
            height: rem(32);
            background: rgba(230,230,230,.1);
          }
          &:after{
            content: '';
            position: absolute;
            right:0;
            top:rem(12);
            width: rem(2);
            height: rem(32);
            background: rgba(230,230,230,.1);
          }
        }
      }
    }
  }
  .share_icon_weixin{
    &:hover{
      &:after{
        right:auto;
      }
    }
  }
  .zy_footer .zy_policy{
    color: rgba(255,255,255,.3);
  }
  .zy_policy a{
    margin-right: 0;
    padding: rem(3) 0;
    white-space: nowrap;
    display: block;
  }
}
</style>
