<template>
  <div id="zyPage" class="zy_page">
    <Banner />
    <Aboutnav />
    <Develop />
    <Culture />
    <Honor />
  </div>
</template>

<script>
import { aboutnav } from "@/config/about";
import Banner from "./components/Banner";
import Aboutnav from "./components/Aboutnav";
import Develop from "./components/Develop";
import Culture from "./components/Culture";
import Honor from "./components/Honor";

export default {
  name: "About",
  components: {
    Banner,
    Aboutnav,
    Develop,
    Culture,
    Honor
  },
  data: function() {
    return {
     aboutnav: aboutnav, // 导航列表
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
@keyframes fadeInDown {
  from {
    opacity: 0.05;
    transform: translate3d(0, -360px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0.05;
    transform: translate3d(0, -360px, 0);
  }
}

.fade-down-enter-active {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
}

.fade-down-leave-active {
  animation-name: fadeOutUp;
  animation-duration: 0.3s;
}
</style>
