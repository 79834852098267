<template>
  <div id="resp" class="zy_resp">
    <div class="zy_constr">
      <div class="zy_resp_logo" />
      <p ref="contentEl" v-html="resp.desc" :class="flag?'ell3':''"></p>
      <div v-if="flag" class="more_btn">
        <a class="text-blue" @click="flag = !flag">{{flag?'查看更多':''}}</a>
      </div>
      <div class="zy_resp_list">
        <router-link v-for="resp in respList" :key="resp.id" :to="{path:'/allreps', query: { id: resp.id}}">
          <img :src="resp.img" alt class="pc" /><!--ispc?resp.img:resp.img2-->
          <img :src="resp.src" alt="" class="phone_app" />
          <span class="resp_pc_tit">{{resp.title}}</span>
          <span class="resp_app_tit" v-html="resp.apptitle"></span>
          <em>查看详情</em>
        </router-link>
      </div>
      <router-link class="zy_arrow_active" to="/allreps">其它公益活动</router-link>
    </div>
  </div>
</template>

<script>
import { setting } from "@/config";
export default {
  name: "Resp",
  data: function() {
    const { resp } = setting;
    const { respList } = resp;
    return { 
      resp, 
      respList,
      flag: false,
    };
  },
  mounted() {
    this.flag = true
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.phone_app,.resp_app_tit{
  display: none;
}
.zy_resp {
  .zy_resp_logo {
    padding: 70px 0px 48px;
    width: 194px;
    height: 45px;
    background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/resp_logo.87e045c1.png") no-repeat left 70px;
    background-size: 100%;
  }

  .zy_resp_list {
    display: flex;
    justify-content: space-between;
    margin: 40px 0px 48px;
    a {
      display: inline-block;
      width: 236px;
      height: 300px;
      border-radius: 2px;
      text-align: center;
      position: relative;
      overflow: hidden;

      img {
        width: 236px;
        height: 300px;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.05);
          -webkit-transform: scale(1.05);
        }
      }

      span {
        font-size: 22px;
        line-height: 24px;
        width: 100%;
        position: absolute;
        z-index: 1;
        bottom: 32px;
        left: 0px;
        color: #fff;
        text-align: center;
        pointer-events: none;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 0px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
        pointer-events: none;
      }
    }
    em{
      display: none;
    }
  }

  .zy_arrow_active {
    display: block;
    text-align: center;
    margin-bottom: 70px;

    &:hover {
      color: $mainHover!important;
      &::after {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_hover.png")!important;
      }
    }

    &:active {
      color: $mainActived!important;
      &::after {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_visited.png")!important;
      }
    } 
  }
  .more_btn{
    display: none;
  }
}
.S{
  .pc{
    display: none;
  }
  .phone_resp{
    display: block;
  }
  .zy_resp{
    padding-bottom: rem(124);
    background: #F5F5F7;
    .zy_resp_logo{
      margin:0 auto;
      height: rem(83);
      padding: rem(164) 0 rem(80);
      background-position: center;
      background-size: rem(343) rem(83);
    }
    p{
      margin:0 rem(60);
      font-size:rem(42);
      color:#666;
    }
    .ell3{
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .zy_resp_list{
      display: block;
      margin:rem(82) rem(44) 0;
      overflow: hidden;
      text-align: center;
      .phone_app{
        display: block;
      }
      a{
        float: left;
        width: 50%;
        height: rem(694);
        padding:rem(32) rem(16);
        text-align: left;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        img{
          width: rem(464);
          height: rem(384);
          margin:0 auto;
          -webkit-border-radius:rem(6) rem(6) 0 0;
          border-radius:rem(6) rem(6) 0 0;
        }
        &:after{
          display: none;
        }
        .resp_pc_tit{
          display: none;
        }
        .resp_app_tit{
          display: block;
          width:rem(464);
          height: rem(198);
          position: relative;
          left:auto;
          bottom:auto;
          margin:0 auto;
          padding:rem(24) rem(40) 0;
          font-size:rem(48);
          color:#333;
          text-align: left;
          background: #fff;
          box-sizing: border-box;
        }
        em{
          display: block;
          width:rem(464);
          height: rem(130);
          padding:0 rem(40);
          margin: 0 auto;
          background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_nexticon.png") no-repeat rem(203) rem(17);
          -webkit-background-size:rem(20);
          background-size:rem(20);
          font-size: rem(36);
          color:#999;
          font-style: normal;
          background-color: #fff;
          box-sizing: border-box;
          -webkit-border-radius:0 0 rem(6) rem(6);
          border-radius:0 0 rem(6) rem(6);
        }
      }
    }
    .zy_arrow_active{
      margin-bottom: 0;
      padding-top:rem(80);
      color:$mainActive;
      font-size:rem(36);
      &:after{
        background: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_active.png") no-repeat center;
        -webkit-background-size: rem(60);
        background-size: rem(60);
      }
    }
    .more_btn{
      display: block;
      padding-top:rem(72);
      text-align: center;
      .text-blue{
        display: inline-block;
        width: rem(304);
        height: rem(82);
        line-height: rem(82);
        text-align: center;
        border:rem(3) solid #999;
        color:#999;
        font-size: rem(36);
        -webkit-border-radius:rem(6);
        border-radius:rem(6);
      }
    }
  }
}
</style>
