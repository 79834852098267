<template>
  <div id="copyright" class="zy_copyright">
    <div class="zy_title zy_copyright_title">{{copyright.title}}</div>
    <div class="zy_copyright_info">
      <el-tabs v-model="activeName" @tab-click="clickTab">
        <el-tab-pane
          v-for="info in infoList"
          :key="info.name"
          :label="info.name"
          :name="info.name"
        />
      </el-tabs>
      <p class="zy_copyright_detail">{{currentData.desc}}</p>
    </div>
    <div id="copyright_pics" class="zy_coryright_pics">
      <swiper class="swiper" ref="mySwiper" :options="swiperOption" :key="index">
        <swiper-slide class="pic" v-for="(item, idx) in currentData.pics" :key="item + idx">
          <img :src="item" />
        </swiper-slide>
      </swiper>
    </div>
    <div class="zy_coryright_pics">
      <swiper class="swiper" ref="mySwiper" :options="swiperOptionSec" :key="index">
        <swiper-slide class="pic" v-for="(item, idx) in currentData.pics" :key="item + idx">
          <img :src="item" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { setting } from "@/config";
export default {
  name: "Copyright",
  data: function() {
    const { copyright } = setting;
    const { infoList } = copyright;
    return {
      copyright,
      infoList,
      activeName: infoList && infoList[0] && infoList[0].name,
      index: 0,
      swiperOption: {
        loop: true,
        speed: 1500,
        slidesPerView: 7,
        autoplay: { delay: 5000 },
        spaceBetween: 16
      },
      swiperOptionSec: {
        loop: true,
        speed: 1000,
        slidesPerView: 3.5,
        centeredSlides: true,
        autoplay: true,
        spaceBetween: 8.5
      }
    };
  },
  computed: {
    currentData: function() {
      return this.infoList[this.index];
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    clickTab(tab) {
      this.swiper.update();
      const curIdx = tab.index ? Number(tab.index) : 0;
      this.activeName = tab.label;
      this.index = curIdx;
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zy_copyright {
  height: 739px;
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/copyright_bg.a738ab31.png");
  background-size: 100% 739px;
  background-repeat: no-repeat;
  .zy_copyright_title {
    color: #fff;
    padding: 70px 0px 48px;
  }
}
.el-tabs__nav-prev,
.el-tabs__nav-next {
  display: none;
}
.el-tabs__nav{width: 100%;}
.zy_copyright_info {
  padding: 0px 224px;

  .el-tabs {
    padding: 0px 112px;
  }

  .el-tabs__header {
    margin: 0 0 32px;
  }

  .el-tabs__item {
    width: 25%;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    font-weight: normal;
    padding: 0px 68px;
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .el-tabs__item.is-active {
    color: $mainActive;
  }

  .el-tabs__item.is-top:nth-child(2) {
    padding-left: 68px !important;
  }

  .el-tabs__item.is-top:last-child {
    padding-right: 68px!important;
  }

  .el-tabs__active-bar {
    width: 25% !important;
    left: -68px;
    background-color: $mainActive;
  }

  .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .zy_copyright_detail {
    color: #fff;
  }
}
.zy_coryright_pics {
  font-size: 0px;
  padding: 48px 21px 0px 21px;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.5s linear;
  box-sizing: border-box;

  .pic {
    display: inline-block;
    width: 186px;
    margin-right: 16px;
    background: #000;

    img {
      width: 100%;
      height: 100%;
    }
    &:nth-last-child(2) {
      margin-right: 21px;
    }
  }
}

.S .zy_copyright {
  height: rem(1733);
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/copyright_bg_s.878e8c58.png");
  .zy_copyright_title {
    font-size: rem(60);
    padding: rem(164) rem(60) rem(95);
    line-height: rem(84);
  }
}

.S .zy_copyright_info {
  padding: 0 rem(60);
  height: rem(850);
  .el-tabs {
    padding: 0;
  }

  .el-tabs__header {
    margin: 0 0 rem(64);
  }

  .el-tabs__item {
    padding: 0 rem(48);
    font-size: rem(36);
  }

  .el-tabs__nav-wrap {
    padding: 0px;
  }

  .el-tabs__item.is-top:nth-child(2) {
    padding-left: rem(48) !important;
  }

  .el-tabs__active-bar {
    width: rem(240) !important;
    left: rem(-48);
    background-color: $mainActive;
  }

  .el-tabs__nav-prev,
  .el-tabs__nav-next {
    display: none;
  }

  .zy_copyright_detail {
    font-size: rem(36);
    line-height: rem(64);
  }
}

.S .zy_coryright_pics,
.zy_coryright_pics[id] {
  display: block;
}

.S .zy_coryright_pics[id],
.zy_coryright_pics {
  display: none;
}



.S .zy_coryright_pics {
  padding: rem(88) rem(0) rem(124);
  .pic {
    width: rem(297) !important;
    margin: 0 rem(25/2);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
