<template>
    <div id="alldevelop" class="alldevelop">
        <div class="developbanner"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/banner_develop.d56cb44d.png" alt=""></div>
        <Breadbox />
        <div class="all_container">
            <div class="developlist" v-for="item in developList" :key="item.year">
                <div class="develop_item" v-for="items in item.list" :key="items.list">
                    <div class="list_date">{{item.year}}年{{items.date}}</div>
                    <div class="list_info">
                        <ul class="info_list">
                            <li v-if="isString(items.info)">{{items.info}}</li>
                            <li v-else v-for="itm in items.info" :key="itm">{{itm}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <dl>
                <template>
                    <dt></dt>
                    <dd></dd>
                </template>
            </dl>
        </div>
    </div>
</template>
<script>
import { developList } from "@/config/about";
import Breadbox from "@/components/Breadbox";
export default {
    name:'alldevelop',
    data:function(){
        return{
            developList
        }
    },
    components: {
        Breadbox,
    },
    methods: {
        isString(obj) {
            return typeof obj !== 'object'
        }
    }
}
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/mixin.scss";
.alldevelop{margin-top: 82px;}
.developbanner{
    text-align: center;
    img{display:block;width:100%}
}
.all_container{
    width: 992px;
    margin: 0 auto;
    padding: 88px 106px;
    box-sizing: border-box;
    .developlist{
        .develop_item{
            line-height: 32px;
            margin-bottom: 32px;
            overflow: hidden;
        }
        .list_date{
            float: left;
            font-size:18px;
            color:#333;
            font-weight: bold;
        }
        .list_info{
            margin-left:158px;
            font-size:16px;
            color:#666;
             .info_list {
                list-style-type: disc;
                li {
                    list-style: disc;
                    width: 100%;
                    float: unset;
                    padding: 0px;
                }
            }
        }
    }
}
</style>