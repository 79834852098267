<template>
  <div id="about_nav" class="about_nav">
      <div class="inner_nav">
          <span
            class="zy_subnav_a"
            v-for="anchor in anchorList"
            :key="anchor.id"
            @click="toAnchor(anchor)"
            :class="{ active: activeAnchor === anchor.id }"
          >{{ anchor.name }}</span>
      </div>
  </div>
</template>

<script>
//import { setting } from "@/config";
import { aboutnav } from "@/config/about";
// import {isMobile} from '@/utils/utils'
export default {
  name: "Aboutnav",
  data: function() {
    return {
      anchorList: aboutnav, // 导航列表
      offSet: 0, // 锚点位置相对于原位置的偏移量
      fixedHeader: true, // 是否存在固定页头
      headerHeight: 0, // 页头高度80
      stepHeight: 60, //分布定位每次渲染移动多少像素
      activeAnchor: "", // 当前定位的锚点
      anchorPosition: [], //存放各锚点位置
      scrollTop: 0,
      showMenu: false
    };
  },
  mounted: function() {
    let id = this.$route.query.id

    if(id){
      setTimeout(()=> {
      this.toAnchor(id,true)

      }, 500)

    }
    this.initPosition();
  },
  destroyed: function() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    initPosition() {
      if (this.anchorList.length === 0) {
        return;
      }
      // 如果为动态锚点，自动定位当前所属锚点
      window.addEventListener("scroll", this.onScroll, true);
      // this.onScroll();
      this.activeAnchor = null;
      this.anchorPosition = [];
    },
    onScroll() {
      if (this.anchorList.length === 0) {
        return;
      }
      if (this.anchorPosition.length === 0) {
        this.anchorList.forEach(anchor => {
          const toElement = document.querySelector("#" + anchor.id);
          if (toElement) {
            this.anchorPosition.push({
              id: anchor.id,
              position:
                toElement.offsetTop +
                (this.fixedHeader ? -this.headerHeight : this.headerHeight)
            });
          }
        });
      }
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrolled;
      scrolled = Math.ceil(scrolled);
      // 将锚点位置列表根据锚点位置排序
      this.anchorPosition.sort(function(pre, next) {
        return pre.position - next.position;
      });
      // 根据页面位置进行当前锚点高亮
      for (let i = 0; i < this.anchorPosition.length - 1; i++) {
        if (
          scrolled >= this.anchorPosition[i].position &&
          scrolled < this.anchorPosition[i + 1].position
        ) {
          this.activeAnchor = this.anchorPosition[i].id;
        }
      }
      if (this.anchorPosition.length === 0) {
        return;
      }
      if (
        scrolled >= this.anchorPosition[this.anchorPosition.length - 1].position
      ) {
        this.activeAnchor = this.anchorPosition[
          this.anchorPosition.length - 1
        ].id;
      }
    },
    toAnchor(anchor,flag) {
      let id = '';
      // flag 是判断从首页关于我们进来时用的
      if(flag){
        id = anchor
      }else{
        id = anchor.id;
        if (anchor.clickEvent && !anchor.clickEvent()) {
          return;
        }
      }
      this.activeAnchor = id;
      //找到高亮ID
      const toElement = document.querySelector("#" + id);
      if (toElement) {
        const headerHeight = this.fixedHeader
          ? -this.headerHeight
          : this.headerHeight;
        const scrollTop = toElement.offsetTop + this.offSet + headerHeight - 76;
        if (scrollTop === document.documentElement.scrollTop) {
          return;
        }
        this.changeMenu();
        // if (isMobile()) {
        //     document.body.scrollTop = scrollTop
        // }else{
        //     scrollTo(0, scrollTop);
        // }
        //移动端适配
        document.body.scrollTop = scrollTop;
        //pc端适配
        scrollTo(0, scrollTop);
      }
    },
    changeMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.about_nav {
  height: 57px;
  //position: relative;
  position: sticky;
  top: 79px;
  z-index: 10;
  background-color: #FBFBFB;
  box-shadow: 0 -1px 0 0 #f4f4f4 inset;
}
.inner_nav{
    width: 992px;
    height: 57px;
    margin: 0 auto;
}
.zy_subnav_a{
    display: inline-block;
    line-height: 22px;
    padding: 16px 0 19px;
    margin-right: 80px;
    font-size: 16px;
    color:#666;
    cursor: pointer;
    &.active{
        color:#333;
        padding: 16px 0;
        box-shadow: 0 2px #fc5649, 0 3px #fc5649
    }
}

.S .about_nav {
  height: rem(122);
  top: rem(148);
  .inner_nav{
      width: auto;
      padding:0 rem(40);
  }
  .zy_subnav_a{
      margin-right:rem(88);
      line-height: rem(42);
      padding: rem(40) 0;
      font-size: rem(42);
      &.active{
          position: relative;
          box-shadow:none;
          &:after{
            content:'';
            position: absolute;
            left:50%;
            bottom:rem(3);
            width: rem(168);
            height: rem(6);
            margin-left:rem(-84);
            background: #fc5649;
          }
      }
  }
}
</style>
