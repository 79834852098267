<template>
    <div id="phone_advantage" class="phone_advantage">
        <div class="top_img"><img src="//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/advantage_banner.8bd0cd01.png" alt=""></div>
        <div class="subnav">
            <div class="scroll_left">
                <a class="advantage_nav_a" v-for="advantage in advantageList"
                :key="advantage.id"
                @click="toAnchor(advantage)"
                :class="{ active: activeAnchor === advantage.id }">
                    {{ advantage.name}}
                </a>
            </div>    
        </div>
        <div class="advantage_con" v-for="advantage in advantageList" :key="advantage.name" :id="advantage.id">
            <div class="con_title">{{ advantage.name}}</div>
            <div class="con_advantage_info">{{ advantage.desc}}</div>
        </div>
    </div>
</template>
<script>
import { setting } from "@/config";
export default{
    name:'Advantage',
    data:function(){
        const { about } = setting;
        const { advantageList } = about;
        return{
            about,
            advantageList,
            offSet: 0, // 锚点位置相对于原位置的偏移量
            fixedHeader: true, // 是否存在固定页头
            headerHeight: 80, // 页头高度
            stepHeight: 60, //分布定位每次渲染移动多少像素
            activeAnchor: "", // 当前定位的锚点
            anchorPosition: [], //存放各锚点位置
            scrollTop: 0,
        }
    },
  mounted: function() {
    let id = this.$route.query.id

    if(id){
      this.toAnchor(id,true)
    }
    this.initPosition();
  },
  destroyed: function() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    initPosition() {
      if (this.advantageList.length === 0) {
        return;
      }
      // 如果为动态锚点，自动定位当前所属锚点
      window.addEventListener("scroll", this.onScroll, true);
      this.onScroll();
      this.activeAnchor = null;
      this.anchorPosition = [];
    },
    onScroll() {
      if (this.advantageList.length === 0) {
        return;
      }
      if (this.anchorPosition.length === 0) {
        this.advantageList.forEach(anchor => {
          const toElement = document.querySelector("#" + anchor.id);
          if (toElement) {
            this.anchorPosition.push({
              id: anchor.id,
              position:
                toElement.offsetTop +
                (this.fixedHeader ? -this.headerHeight : this.headerHeight)
            });
          }
        });
      }
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrolled;
      scrolled = Math.ceil(scrolled);
      // 将锚点位置列表根据锚点位置排序
      this.anchorPosition.sort(function(pre, next) {
        return pre.position - next.position;
      });
      // 根据页面位置进行当前锚点高亮
      for (let i = 0; i < this.anchorPosition.length - 1; i++) {
        if (
          scrolled >= this.anchorPosition[i].position &&
          scrolled < this.anchorPosition[i + 1].position
        ) {
          this.activeAnchor = this.anchorPosition[i].id;
        }
      }
      if (this.anchorPosition.length === 0) {
        return;
      }
      if (
        scrolled >= this.anchorPosition[this.anchorPosition.length - 1].position
      ) {
        this.activeAnchor = this.anchorPosition[
          this.anchorPosition.length - 1
        ].id;
      }
    },
    toAnchor(advantage,flag) {
      let id = '';
      // flag 是判断从首页业务优势进来时用的
      //console.log(flag);
      if(flag){
        id = "ad" + (parseInt(advantage) + 1);
      }else{
        id = advantage.id;
        if (advantage.clickEvent && !advantage.clickEvent()) {
          return;
        }
      }
      this.activeAnchor = id;
      const toElement = document.querySelector("#" + id);
      if (toElement) {
        const headerHeight = this.fixedHeader
          ? -this.headerHeight
          : this.headerHeight;
        const scrollTop = toElement.offsetTop + this.offSet + headerHeight;
        if (scrollTop === document.documentElement.scrollTop) {
          return;
        }
        document.body.scrollTop = scrollTop;
        //pc端适配
        scrollTo(0, scrollTop);
      }
    },
    changeMenu() {
      //this.showMenu = !this.showMenu;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.phone_advantage{display: none;}
.S{
    .phone_advantage{
        padding-bottom: rem(116);
        display: block;
    }
    .top_img{
        img{width: 100%;display: block;}
    }
    .advantage_nav_a{
        display: inline-block;
        padding:0 rem(44);
        text-align: center;
        font-size:rem(42);
        color:#333;
        line-height: rem(122);
        &.active{
            position: relative;
            &:after{
                content:'';
                position: absolute;
                left:50%;
                bottom:rem(3);
                width:rem(168);
                height: rem(6);
                margin-left:rem(-84);
                background: #FC5649;
            }
        }
    }
    .subnav{
        position: sticky;
        top: rem(145);
        height: rem(122);
        background: #FBFBFB;
        box-shadow: 0 rem(-3) 0 0 #f5f5f5 inset;
        .scroll_left{
            height: rem(122);
            padding: 0 rem(16);
            overflow-x:scroll;
            white-space: nowrap;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .advantage_con{
        padding-top:rem(116);
        .con_title{
            width: rem(620);
            height: rem(148);
            line-height: rem(148);
            padding-left:rem(60);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background: linear-gradient(145deg,#F7F8FA,rgba(247,248,250,0));
            font-size:rem(60);
            color:#333;
        }
        .con_advantage_info{
            padding:rem(48) rem(60) 0;
            font-size:rem(42);
            color:#666;
            line-height: 1.5;
        }
    }
}
</style>
