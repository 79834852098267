<template>
  <div id="invest" class="zy_invest">
    <div class="zy_constr">
      <h1 class="zy_title zy_invest_title">{{invest.title}}</h1>
      <p v-html="invest.desc" />
      <div class="zy_invest_list">
        <a
          class="zy_arrow_white"
          v-for="invest in investList"
          :key="invest.name"
          :href="invest.href"
          target="_blank"
        >{{invest.name}}</a>
      </div>
      <div class="zy_invest_contact">
        <h4>联系方式</h4>
        <p v-for="item in contact" :key="item.type">{{item.type}}：{{item.msg}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { setting } from "@/config";
export default {
  name: "Invest",
  data: function() {
    const { invest } = setting;
    const { investList, contact } = invest;
    return { invest, investList, contact };
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.zy_invest {
  height: 567px;
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/invest_bg.7c57db3d.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  .zy_invest_title {
    color: #fff;
    text-align: left;
    padding: 116px 0px 16px;
  }
  p {
    width: 496px;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
  }

  .zy_invest_list {
    margin: 32px 0px 64px;
    a {
      display: inline-block;
      width: 126px;
      height: 34px;
      padding-left: 7px;
      box-sizing: border-box;
      border: 0.5px solid #fff;
      border-radius: 2px;
      text-align: center;
      line-height: 36px;
      margin-right: 16px;
      font-size: 12px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }
      &.zy_arrow_white{
        &:after{
          background-position: center 1px;
        }
      }
    }
  }

  .zy_invest_contact {
    h4 {
      font-size: 16px;
      line-height: 32px;
    }
    p {
      font-size: 14px;
      color: #ffbebe;
      line-height: 24px;
    }
  }
}
.S .zy_invest{
  height: rem(1286);
  background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/phone_invest_bg.1ac28fb3.png");
  background-size: 100%;
  background-repeat: no-repeat;
  .zy_invest_title{
    padding:rem(164) 0 0;
    text-align: center;
    font-size: rem(60);
  }
  p{
    width: auto;
    padding: rem(56) rem(60) 0;
    font-size: rem(42);
    color:#fff;
  }
  .zy_invest_list{
    margin:0;
    padding: rem(98) 0 rem(224);
    text-align: center;
    a{
      width: auto;
      height: rem(50);
      line-height: rem(50);
      text-align: center;
      font-size:rem(42);
      color:#fff;
      border:0;
      margin:0 rem(65);
    }
  }
  .zy_invest_contact{
    padding: 0 rem(60);
    height: auto;
    h4{
      position: relative;
      margin-bottom: rem(36);
      font-size: rem(48);
      &:before{
        content:'';
        position: absolute;
        left:0;
        top:rem(-43);
        height: rem(3);
        width: rem(216);
        background: rgba(255, 255, 255, .2);
      }
    }
    p{
      padding: 0;
      font-size: rem(42);
      color:rgba(255, 255, 255, .7)
    }
  }
}
@media screen and (min-width:320px) and (max-width:380px) {
  .S .zy_invest .zy_invest_list a{
    margin:0 rem(25);
  }
  .S .zy_invest .zy_invest_list{
    padding: rem(60) 0 rem(124);
  }
  .S .zy_invest{
    height: rem(1146);
  }
}
</style>
