<template>
  <div id="news" class="zy_news">
    <div class="zy_constr">
      <h1 class="zy_title zy_news_title">{{news.title}}</h1>
      <div class="zy_news_content">
        <div class="zy_news_list">
          <h2 class="zy_title_sec">掌阅动态</h2>
          <!-- !news.showmore?index < shouwLen :news.children -->
          <div v-for="(item,index) in infoList.list" :key="index" >
            <p v-if="index<4">
              <a :href="item.href" target="_blank">{{item.title}}</a>
              <span>{{item.date}}</span>
            </p>
          </div>
          <router-link class="zy_arrow_active" to='/allnews'>更多动态</router-link>
        </div>
        <div calss="zy_news_imgs">
          <div v-for="(news,i) in infoList.list_img" :key="i">
            <div class="zy_news_img"  v-if="i<3">
              <a :href="news.href" target="_blank">
                <img :src="news.img" alt />
              </a>
              <a :href="news.href" target="_blank">
                <p>{{news.title}}</p>
              </a>
            </div>
          </div>  
        </div>
      </div>
      <swiper class="news_swiper" ref="newsSwiper" :options="swiperOption">
        <swiper-slide class="news_slider" v-for="news in infoList.list_img" :key="news.title">
          <div class="news_list">
            <a :href="news.href" target="_blank" class="news_img"><img  :src="news.img" alt /></a>
            <a class="news_btminfo" :href="news.href" target="_blank">
              <div class="news_time">{{news.date}}</div>
              <div class="news_title">{{news.title}}</div>
              <div class="news_more"><span>查看详情</span></div>
            </a>
          </div>
        </swiper-slide>
        <div class="swiper-pagination newspagination" slot="pagination"></div>
      </swiper>
      <router-link class="zy_more_btn" to='/allnews'>更多动态</router-link>
    </div>
  </div>
</template>

<script>
import { setting } from "@/config";
import { Mixins } from '@ireader/core-lib';
export default {
  name: "News",
  mixins: [Mixins.sdd()],
  data: function() {
    const { news } = setting;
    // const { infoList } = news;
    return {
      news,
      infoList: [],
      showLen:4,
      swiperOption: {
        loop: true,
        speed: 1500,
        slidesPerView: 'auto',
        centeredSlides: true,
        paginationClickable: true,
        spaceBetween: 10,
        pagination: {
          el: '.newspagination',
          clickable: true
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.newsSwiper.swiper;
    }
  },
  mounted() {
    this.getNewsList();
  },
  methods: {
    async getNewsList () {
      const { news: { infoList } } = setting;
      try {
          const res = await this.$getSdd('zycom/news_ digest')
          this.infoList = res || infoList;
      } catch (e) {
        this.infoList = infoList;
      }
    },
    pagination(){
      this.$refs.newsSwiper.$swiper;
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
@import "@/styles/mixin.scss";
.zy_news {
  height: 627px;
}
.zy_more_btn{
  display: none;
}
.zy_news_title {
  padding: 70px 0 64px 0;
}

.zy_news_content {
  display: flex;

  .zy_news_list {
    width: 500px;
    margin-right: 40px;

    .zy_title_sec {
      &::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #e6e6e6;
        margin-bottom: 24px;
        margin-top: 24px;
      }
    }

    p {
      display: flex;
      margin-bottom: 40px;
      a {
        width: 415px;
        padding-right: 24px;
        font-size: 16px;
        display: inline-block;
        @include ellipsis;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          color: $mainActive;
        }
      }
      span {
        flex: 1;
        font-size: 12px;
        text-align: right;
        color: #999;
        font-family: Helvetica Neue;
      }
    }

    .zy_news_more {
      color: $mainActive;
      font-size: 12px;
      line-height: 17px;
      &::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_active.png");
        background-size: 100% 100%;
        vertical-align: bottom;
      }
    }
  }

  .zy_news_img {
    display: flex;
    height: 110px;
    margin-bottom: 16px;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    overflow:hidden;
    img {
      width: 170px;
      height: 110px;
    }
    p {
      margin: 24px 16px 0px;
      font-size: 16px;
      color: #666;
      line-height: 28px;
      &:hover {
        color: $mainActive;
      }
    }
  }

  .zy_arrow_active {

    &:hover {
      color: $mainHover!important;
      &::after {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_hover.png")!important;
      }
    }

    &:active {
      color: $mainActived!important;
      &::after {
        background-image: url("//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/arrow_visited.png")!important;
      }
    } 
  }
}
.news_swiper{
  display: none;
}

.S{
  .zy_news_title{
    padding:rem(164) 0 rem(80);
    font-size:rem(60);
    color:#333;
  }
  .news_img{
    display: block;
    //width:rem(897);
    height: rem(505);
    img{
      width: 100%;
      height: 100%;
    }
  }
  .news_btminfo{
    display: block;
    padding: 0 rem(56);
    .news_time{
      padding-top:rem(64);
      color:#666;
      font-size:rem(36);
    }
  }
  .news_title{
    padding-top:rem(32);
    color:#333;
    font-size:rem(48);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .news_more{
    padding-top:rem(54);
    font-size:rem(36);
    color:#999;
    span{
      display: inline-block;
      padding-right:rem(32);
      background: url('//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/more.png') no-repeat right center;
      -webkit-background-size:rem(20);
      background-size:rem(20);
    }
  }
  .news_list{
    width: 100%;
    height: rem(1049);
    background: #fff;
    -webkit-border-radius:rem(6);
    border-radius:rem(6);
    overflow:hidden
  }
  .zy_news_content{
    display: none;
  }
  .news_slider{
    // width: 60%!important;
    width: 80%;
    height: rem(1049);
    // margin-right: rem(300)!important;
  }
  .news_swiper{
    display: block;
    padding-left:rem(60);
    height: rem(1200);
    .swiper-pagination{
      //bottom: rem(-96);
    }
     .swiper-pagination-bullet {
      margin: 0 rem(20);
      background: none;
      border: rem(3) solid rgba(0, 0, 0, .5);
    }
    .swiper-pagination-bullet-active {
      background: rgba(0, 0, 0, 0.5);
      border: rem(3) solid rgba(0, 0, 0, 0);
    }
  }
  .zy_news{
    background: #F5F5F7;
  }
  .zy_more_btn{
    display: block;
    width: rem(304);
    height: rem(82);
    text-align: center;
    margin:rem(84) auto 0;
    line-height: rem(82);
    border:rem(3) solid #999;
    -webkit-border-radius:rem(6);
    border-radius:rem(6);
    font-size: rem(36);
    color:#999;
  }
}
</style>
