<template>
    <div id="develop" class="develop">
        <div class="swiper_w" id="swiper_w">
            <div class="content-container">
                <div class="scroll-content" :style="getStyle()">
                    <div class="list_item" v-for="item in developList" :key="item.year">
                        <div class="swiperinner">
                            <div class="develop_year"><span>{{item.year}}</span></div>
                            <ul class="develop_list">
                                <li v-for="item in item.list" :key="item.list">
                                    <div class="month_bold">{{item.date}}</div>
                                    <div class="develop_info">
                                        <ul class="info_list">
                                            <li v-if="isString(item.info)">{{item.info}}</li>
                                            <li v-else v-for="itm in item.info" :key="itm">{{itm}}</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>   
                </div> 
            </div>
            <!--swiper class="swiper" ref="mySwiper" :options="swiperOption">
                <swiper-slide >
                    
                </swiper-slide>
            </swiper>
            <div class="slider_btn">
                <div class="swiper-button-prev" @click="prevBtn"></div>
                <div class="swiper-button-next" @click="nextBtn"></div>
            </div-->
        </div>
       <router-link class="zy_arrow_active more_develop" to="/alldevelop">全部历程</router-link>
       <div class="phone_wrap">
           <div class="about_title">发展历程</div>
           <div class="all_container" :class="flag?'h_hide':''">
                <div class="developlist" v-for="item in developList" :key="item.year">
                    <div class="develop_item" v-for="items in item.list" :key="items.list">
                        <div class="list_date">{{item.year}}年{{items.date}}</div>
                        <div class="list_info">
                            <ul class="info_list">
                                <li v-if="isString(items.info)">{{items.info}}</li>
                                <li v-else v-for="itm in items.info" :key="itm">{{itm}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="flag" class="more_box">
                <a class="text-blue" @click="flag = !flag">{{flag?'查看更多':''}}</a>
            </div>
       </div>
    </div>
</template>
<script>
import { developList } from "@/config/about";
export default {
    name:'Develop',
    data: function(){
        return {
            developList,
            flag: false,
            marginLeft: 0,
            scrollContent: {
                contentWidth: 0,
                wrapWidth: 0,
                maxDistance: 0
            }
        }
    },
    mounted() {
        this.flag = true
        const current = document.getElementById('swiper_w')
        if (current) {
            current.addEventListener('mousedown', this.onMouseDown, true)
            if (this.scrollCurrent) {
                const oSbar = this.scrollCurrent
                this.scrollContent = {
                    contentWidth: oSbar.scrollWidth,
                    wrapWidth: oSbar.offsetWidth,
                    maxDistance: oSbar.scrollWidth - oSbar.offsetWidth
                }
            }
        }
    },
    destroyed() {
        const current = document.getElementById('swiper_w')
        if (current) {
            current.removeEventListener('mousedown', this.onMouseDown, true)
        }
    },
    computed: {
        scrollCurrent() {
            const swiperW = document.getElementById('swiper_w')
            if (swiperW && swiperW.getElementsByClassName('scroll-content') && swiperW.getElementsByClassName('scroll-content')[0]) {
                return swiperW.getElementsByClassName('scroll-content')[0]
            }
            return null
        },
    },
    methods: {
        getStyle() {
            return { marginLeft: `${this.marginLeft}px` }
        },
        isString(obj) {
            return typeof obj !== 'object'
        },
        onMouseDown(e) {
            const oEvent = e || e.event;
            const oSbar = this.scrollCurrent;
            const startX = oEvent.clientX || (oEvent.touches && oEvent.touches[0] && oEvent.touches[0].clientX);
            const startMarginLeft = oSbar.style.marginLeft ? Number(oSbar.style.marginLeft.slice(0, oSbar.style.marginLeft.length - 2)) :  0;

            const fnMove = (e) => {
                const oEvent = e || e.event;
                const endX = oEvent.clientX || (oEvent.touches && oEvent.touches[0] && oEvent.touches[0].clientX);
                let left = startMarginLeft + (endX - startX)
                left >= 0 && (left = 0);
                const { contentWidth, wrapWidth, maxDistance} = this.scrollContent;
                -left + wrapWidth >= contentWidth && (left = -maxDistance),
                this.marginLeft = left
            }
  
            const fnUp = () => {
                document.onmousemove = null;
                document.onmouseup = null;
                if(oSbar.releaseCapture)oSbar.releaseCapture();
            }
                
            if (oSbar.setCapture) {
                oSbar.onmousemove = fnMove;
                oSbar.onmouseup = fnUp;
                oSbar.setCapture();
            } else {
                document.onmousemove = fnMove;
                document.onmouseup = fnUp;
            }
  
            return false;
        }

    }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.phone_wrap{display: none;}
.develop{
    padding-top:88px;
    .zy_arrow_active{
        display: block;
        padding: 165px 0 88px;
        text-align: center;
        font-size:14px;
        color:#FC5649;
    }
}
.swiper_w{
    position: relative;
    width: 100%;
    cursor: url('//static-efe-front-h.zhangyuecdn.com/sfm-production/zycom/cursor_swiper.png'),move;
    -ms-touch-action: none;
    touch-action: none;
    .content-container{
        padding-left: 224px;
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        .scroll-content {
            display: flex;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            height: 100%;
            color: #000;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            .list_item{
                display: inline-block;
                white-space: nowrap;
                vertical-align: top;
                -webkit-flex-shrink: 1;
                -ms-flex-negative: 1;
                flex-shrink: 1;
            }
        }
       
    }
}
.swiper-container{
    position: relative;
}
.swiper_w li{
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding: 32px 50px 0 0;
    list-style: none;
}

.develop_list{overflow: hidden;padding: 0;}
.develop_year{
    position: relative;
    height: 45px;
    line-height: 45px;
    font-size: 32px;
    color:#FC5649;
    width: 992px;
    &:after{
        content:'';
        position: absolute;
        right:0;
        left:87px;
        top:50%;
        height: 1px;
        background-color: #f5f5f5;
    }
}
.month_bold{
    font-size: 24px;
    color:#333;
    padding-left:16px;
    position: relative;
    &:before{
        content:'';
        position: absolute;
        left:0;
        top:50%;
        width: 8px;
        height: 8px;
        margin-top: -4px;
        border-radius: 8px;
        background: linear-gradient(45deg,#FF7252,#FF4D40);
    }
}
.develop_info{
    line-height: 28px;
    padding-top:12px;
    font-size:16px;
    color:#666;
    white-space: pre-wrap;
    .info_list {
        list-style-type: disc;
        li {
            list-style: disc;
            width: 100%;
            float: unset;
            padding: 0px;
        }
    }
}
// .swiperinner{
//     display: inline-block;
// }
// .slider_btn{
//     width: 47px;
//     height: 35px;
//     position:absolute;
//     right:225px;
//     bottom:-72px;
//     background: url("../../../assets/images/aboutpage/swiper_btnbg.png") no-repeat center;
//     .swiper-button-prev{
//         width: 6px;
//         height: 10px;
//         margin-top: -6px;
//         background: url("../../../assets/images/aboutpage/prew.png") no-repeat center;
//         z-index: 5;
//     }
//     .swiper-button-next{
//         width: 6px;
//         height: 10px;
//         margin-top: -6px;
//         background: url("../../../assets/images/aboutpage/next.png") no-repeat center;
//         z-index: 5;
//     }
// }
.S{
    .swiper_w,.more_develop{
        display: none;
    }
    .phone_wrap{
        display: block;
    }
    .about_title{
        padding: rem(148) 0 0;
        font-size:rem(60);
        color:#333;
        font-weight: bold;
    }
    .all_container{
        width: auto;
        padding:rem(80) 0 rem(96);
        .developlist{
            .list_date{
                font-size:rem(42);
                color:#333;
            }
            .list_info{
                margin-left:rem(316);
                font-size:rem(42);
            }
        }
        &.h_hide{
            height: rem(1872);
            overflow: hidden;
        }
    }
    .more_box{
        display: block;
        padding: rem(96) 0 rem(124);
        text-align: center;
        .text-blue{
        position: relative;
        display: inline-block;
        padding-right:rem(32);
        font-size:rem(36);
        color:#999;
        &:after{
            content:'';
            position: absolute;
            right:0;
            top:50%;
            width: rem(16);
            height: rem(16);
            margin-top:rem(-8);
            -webkit-transform: rotate(90deg);
            transform:  rotate(90deg);
            background:url('../../../assets/images/more.png') no-repeat center;
            -webkit-background-size:rem(16);
            background-size:rem(16);
        }
        }
    }
    .develop{
        padding:0 rem(72);
    }
}
</style>