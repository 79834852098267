<template>
    <div id="notice" class="notice">
        <div class="inner_con">
            <h1>法律声明</h1>
            <div class="first_con">欢迎访问掌阅书城（www.zhangyue.com）。掌阅网站（以下简称"本站"）是掌阅科技股份有限公司经营的专业手机阅读网站，为了规范网站管理，依法保障本网站及相关版权所有者的合法权利，本公司特作出以下法律声明。本法律声明阐述之条款和条件适用于所有访问和使用本网站的个人或机构。</div>
            <ul>
                <li>
                    <div class="sub_title">1.网站的使用</div>
                    <div class="subcon">本站所载述的域名、商标、商号、信息、文字、图形、图像、声音、链接、软件及所有其它内容均由本公司、其内容提供商及第三方许可人提供。上述内容的知识产权依具体情况分别为本公司、其内容提供商及第三方许可人所拥有。 除法律另有规定或双方另有约定外，未经本公司或商标权人事先许可，任何单位及个人不得以任何方式或理由对本网站的商标、商号或标识的任何部分进行使用、复制、修改、传播或与其它。</div>
                </li>
                <li>
                    <div class="sub_title">2.版权声明</div>
                    <div class="subcon">本站所提供内容均具有合法的著作权或者已取得合法著作权人的合法授权与转授权。并已取得相关信息所需的全部审批、许可或备案。未经本公司和/或其他权利人事先许可，任何人不得以任何形式通过任何方式复制、修改、转让、分发、重新发布、下载、张贴或传输本网站的内容。</div>
                </li>
                <li>
                    <div class="sub_title">3.客户端软件声明</div>
                    <div class="subcon">本站所提供客户端软件，在任何的形式和程度上，没有涉及破译并修改第三方程序源代码、数据封包，或者对内存数据进行解密和写入操作。不侵犯任何第三方的合法权益。</div>
                    <div class="subcon">本站所提供客户端软件，已在中华人民共和国国家版权局依法登记，受到《计算机软件保护条例》、《计算机软件著作权法》保护。在使用软件时要遵守该软件附带的软件许可协议中所有的许可条款。在您阅读并接受软件许可协议的各项条款之后，可下载或安装这一软件。</div>
                </li>
                <li>
                    <div class="sub_title">4.免责声明</div>
                    <div class="subcon">a、本站旨在为广大用户提供更多的阅读内容，本站所提供的文章均具有合法的著作权或者已取得合法著作权人的合法授权与转授权，本站对文章的版权归属和权利瑕疵情况不承担核实责任。任何单位或个人认为本站内容可能涉嫌侵犯其合法权益，应该及时向本站书面反馈，并提供身份证明、权属证明及详细情况证明，本站在收到上述文件后将对相关内容采取相应措施。</div>
                    <div class="subcon">b、本站所提供文章仅代表作者本人的观点与本站无关。本站不对其中包含或引用的信息的准确性、可靠性或完整性提供任何明示或暗示的保证。</div>
                    <div class="subcon">c、当政府司法机关依照法定程序要求本站公共安全之目的提供相关资料。在此情况下之任何披露，本网站均得免责。</div>
                    <div class="subcon">d、本网站如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本公司控制范围外的硬件故障或其它不可抗力（包括但不限于黑客攻击、计算机病毒侵入或发作等）而导致暂停服务，于暂停服务期间造成的一切不便与损失，本站均得免责。</div>
                </li>
                <li>
                    <div class="sub_title">5、法律责任</div>
                    <div class="subcon">a、凡以任何方式登陆本网站或直接、间接使用本网站者，视为自愿接受本网站声明的约束。</div>
                    <div class="subcon">b、本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。</div>
                    
                </li>
                <li>
                    <div class="sub_title">6.一般原则</div>
                    <div class="subcon">掌阅可能随时修改这些条款。您应经常访问本页面以了解当前的条款，因为这些条款与您密切相关。这些条款的某些条文也可能被本站中某些页面上明确指定的法律通告或条款所取代，您应该了解这些内容，一旦接受本条款，即意味着您已经同时详细阅读并接受了这些被引用或取代的条款。</div>
                </li>
                <li>
                    <div class="sub_title">7.法律适用与司法管辖</div>
                    <div class="subcon">本法律声明的制定、执行和解释及争议的解决均应适用中华人民共和国法律。双方因使用本网站所产生的争议，协商不成的，任何一方可以向本公司所在地人民法院提起诉讼。</div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name:'thems'
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/function.scss";
.notice{margin-top: 82px;}
.inner_con{
    width: 780px;
    margin:0 auto;
    padding:88px 0;
    h1{
        line-height: 48px;
        text-align: center;
        font-size: 28px;
        color: #333;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E6E6E6;
        font-weight:normal;
    }
    .first_con{
        line-height: 32px;
        font-size:16px;
        color:#666;
    }
    .sub_title{
        font-size:16px;
        color:#333;
    }
    .subcon{
        line-height: 1.9;
        padding-top:16px;
        font-size:16px;
        color:#666;
    }
    li{
        padding-top:32px;
        list-style: none;
    }
    ul{
        padding:0;
    }
}
.S{
    .notice{
        margin-top:rem(148);
    }
    .inner_con{
        width:auto;
        padding:rem(92) rem(60) rem(124);
        h1{
            font-size:rem(60);
            color:#333;
            font-weight: normal;
            padding-bottom: rem(56);
            margin-bottom:rem(72)
        }
        .first_con{
            font-size:rem(42);
        }
        .sub_title{
            font-size:rem(48)
        }
        .subcon{
            font-size:rem(42)
        }
        li{
            padding-top:rem(80)
        }
    }
}
</style>